import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { GridMenuIcon } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import notificationIcon from "../../assets/notification.svg";
import logo from "../../assets/ecomlovers-logo-transparente.svg";
import userIcon from "../../assets/Vector (14).png";
import { Notifications } from "@mui/icons-material";
import useIsMobileDevice from "../../utils/mobileCheck";

export const MainTopBarCompanies = ({
  mainSidebarVisible,
  setMainSidebarVisible,
  notificationsVisible,
  setNotificationsVisible,
  loading,
  notifications,
}) => {
  const { signOut } = useContext(AuthContext);
  const { id, hash } = useParams();
  const { user } = useContext(AuthContext);
  const isMobile = useIsMobileDevice();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const notificationsRead =
    notifications?.length > 0 &&
    notifications?.reduce(
      (acc, item) => (item.readed === false ? acc + 1 : acc),
      0
    );

  const handleAvatar = () => {
    if (user?.avatar) {
      return <Avatar src={user?.avatar} />;
    } else if (user?.name) {
      return <Avatar>{String(user?.name[0]).toUpperCase()}</Avatar>;
    } else if (user?.email) {
      return (
        <Avatar style={{ backgroundColor: "#EC5569" }}>
          {String(user?.email[0]).toUpperCase()}
        </Avatar>
      );
    } else {
      return <Avatar style={{ backgroundColor: "#EC5569" }} />;
    }
  };

  function HideOnScroll(props) {
    const [showAppBar, setShowAppBar] = useState(true);
    const { children } = props;

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;

      // Se o usuário deslizar o dedo de cima para baixo
      if (window.lastScroll > currentScroll) {
        setShowAppBar(true);
      } else {
        setShowAppBar(false);
      }
      window.lastScroll = currentScroll;
    };

    useEffect(() => {
      window.lastScroll = 0; // Inicializa a última posição de scroll
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
      <Slide appear={false} direction="down" in={showAppBar}>
        {children}
      </Slide>
    );
  }

  return (
    // <HideOnScroll>
    // <div>

      <Toolbar sx={{ height: "20px" }}>
        <IconButton
          sx={{
            opacity: 1,
            "&:hover": {
              opacity: 0.8,
            },
          }}
          style={{ padding: 0 }}
          onClick={() => navigate("/companies/painel")}
        >
          <img
            alt="eComLovers logo"
            src={logo}
            style={{ height: isMobile ? "35px" : "45px", flexGrow: 1 }}
          />
        </IconButton>
        <div
          style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
        ></div>

        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <IconButton
            sx={{
              border: "1px solid #ECEFF1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "35px",
              height: "35px",
            }}
            style={{ padding: 0 }}
            // onClick={() => navigate("/settings")}
          >
            <img
              src={userIcon}
              style={{ height: "18px" }}
              alt="notifications bell"
            />
          </IconButton>

          <IconButton
            color="inherit"
            onClick={() => setNotificationsVisible(!notificationsVisible)}
            edge="start"
            style={{
              color: "#211E1F",
              border: "1px solid #ECEFF1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "35px",
              height: "35px",
            }}
          >
            {notificationsRead > 0 ? (
              <Typography
                color={"white"}
                fontSize={12}
                fontWeight={700}
                textAlign={"center"}
                style={{
                  display: "flex",
                  position: "absolute",
                  border: "1px solid #EC5569",
                  backgroundColor: "#EC5569",
                  width: "20px",
                  height: "20px",

                  borderRadius: "50%",
                  marginTop: -25,
                  marginRight: -20,

                  justifyContent: "center",
                  alignItems: "center",

                  padding: 2,
                }}
              >
                {notificationsRead > 9 ? "9+" : notificationsRead}
              </Typography>
            ) : null}

            <img
              src={notificationIcon}
              style={{ height: "18px" }}
              alt="notifications bell"
            />
          </IconButton>
        </div>
      </Toolbar>
    // </div>
    // </HideOnScroll>
  );
};
