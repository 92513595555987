import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import TokenInput from "../components/TokenInput";
import LayoutSpace from "../components/LayoutSpace";
import iconHeart from "../../assets/heart.png";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const { signInCompanies } = useContext(AuthContext);
  const [loadingToken, setLoadingToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("screen1");
  const [token, setToken] = useState(Array(6).fill(""));
  const [checkToken, setCheckToken] = useState(false);

  // const stylesContainer = currentScreen !== 'screen1' && { alignItems: "start" };
  
  // const stylesImage = currentScreen !== 'screen1' && { height: "75vh"};

  const formLogin = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    const { email } = values;
    setLoadingToken(true);
    try {
      const { data } = await api.post(`/companies/request_password`, {
        email: email,
      });
      setLoadingToken(false);
      toast.success("Nova senha enviada para o seu email");
      setCurrentScreen("screen3");
    } catch (error) {
      toast.error("Credenciais inválidas");
    } finally {
      setLoadingToken(false);
    }
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case "screen2":
        return (
          <div className="cardLogin2">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={iconHeart} alt="heart" className="heartIcon" />
            </div>
            <div className="title">Bem-Vindo a eComLovers!</div>

            <div className="containerInputLogin" style={{ height: "100px", width :'100%' }}>
              <div className="text">Preencha com o seu email</div>
              <input
                type="email"
                label="E-mail"
                className="inputLoginCompanies"
                onChange={formLogin.handleChange}
                onBlur={formLogin.handleBlur}
                value={formLogin.values.email}
                name="email"
              />
              {formLogin.touched.email && formLogin.errors.email && (
                <div style={{ color: "red" }} className="textINput">
                  {formLogin.errors.email}
                </div>
              )}
            </div>

            <button className="buttonLogin" onClick={formLogin.handleSubmit}>
              {" "}
              {loadingToken ? (
                <span className="loading">Carregando...</span>
              ) : (
                "Enviar token de acesso"
              )}
            </button>
          </div>
        );
      case "screen3":
        return (
          <div className="cardLogin2">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={iconHeart} alt="heart" className="heartIcon" />
            </div>
            <div className="title">Bem-Vindo a eComLovers!</div>
            <div className="text">
              Acesse seu email e preencha com o token que foi enviado!
            </div>

            <div className="containerInputLogin" style={{ height: "100px" }}>
              <div className="text">Preencha com o token</div>
              <TokenInput token={token} setToken={setToken} />
              <div style={{ color: "red" }} className="text">
                {checkToken && "token é obrigatório"}
              </div>
            </div>

            <button className="buttonLogin" onClick={handleLogin}>
              {loading ? (
                <span className="loading">Carregando...</span>
              ) : (
                "Entrar"
              )}
            </button>
          </div>
        );
      default:
        return (
          <div className="cardLogin">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={iconHeart} alt="heart" className="heartIcon" />
            </div>
            <div className="title">Bem-Vindo a eComLovers!</div>
            <div className="subTitle">
              Olá, Gestor! Pronto para decolar em sua jornada pelo universo do
              e-commerce?
            </div>
            <div className="text">
              Aqui no eComLovers, você encontrará a tripulação perfeita para sua
              missão de sucesso no comércio eletrônico. Estamos ansiosos para
              ajudá-lo a navegar por esse espaço cheio de oportunidades!
            </div>
            <button
              className="buttonLogin"
              onClick={() => {
                navigate("/companies/registro");
              }}
            >
              Vamos começar agora!
            </button>
            <button
              className="buttonRegister"
              onClick={() => setCurrentScreen("screen2")}
            >
              Acessar minha conta
            </button>
          </div>
        );
    }
  };

  const joinStrings = (strings) => {
    return strings.join("");
  };

  const handleLogin = async () => {
    if (checkAllStringsFilled(token)) {
      try {
        setLoading(true);
        await signInCompanies({
          email: formLogin.values.email,
          password: joinStrings(token),
        });
        setLoading(false);
      } catch (error) {
        toast.error("Credenciais inválidas");
      } finally {
        setLoading(false);
      }
    } else {
      setCheckToken(true);
    }
  };

  const checkAllStringsFilled = (strings) => {
    return strings.every((str) => str.trim() !== "");
  };

  return (
    <LayoutSpace >
      {renderScreen(currentScreen)}
    </LayoutSpace>
  );
};

export default Login;
