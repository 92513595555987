import { Box, display } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";

import { MainSidebarCompanies } from "../../components/MainSidebarCompanies/MainSidebarCompanies";
import { MainTopBarCompanies } from "../MainTopBarCompanies/MainTopBarCompanies";
import { ContentContainer } from "./ContentContainer";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../providers/AuthProvider";
import mockNotifications from "../../utils/examples/notifications.json";
import { Toolbar } from "@mui/material";

export const AuthorizedLayoutCompanies = ({ children, styles }) => {
  const [mainSidebarVisible, setMainSidebarVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [supportModal, setSupportModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState({});

  const { user } = useContext(AuthContext);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    setLoading(true);
    try {
      // const { data } = await api.get("talents/notifications");
      const { data } = mockNotifications;

      if (data) {
        setNotifications(
          data
            .map((item) => {
              return {
                notificationId: item?.id,
                ...item?.attributes,
              };
            })
            .sort((a, b) => {
              return b.id - a.id;
            })
        );
      }
      return [];
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível carregar a lista de notificações.");
    } finally {
      setLoading(false);
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  let width = isMobileDevice() ? 10 : 80;

  return (
    <Box
      style={{ backgroundColor: "#f9ffff"}}
    >
      <MainTopBarCompanies
        mainSidebarVisible={mainSidebarVisible}
        setMainSidebarVisible={setMainSidebarVisible}
        notificationsVisible={notificationsVisible}
        setNotificationsVisible={setNotificationsVisible}
        loading={loading}
        notifications={notifications}
      />
      <div style={{ display :'flex', gap :'20px', padding: '0px'}}>
        
        <MainSidebarCompanies />

        <ContentContainer
          styles={styles}
          notificationsVisible={notificationsVisible}
          setNotificationsVisible={setNotificationsVisible}
          loading={loading}
          notifications={notifications}
          setNotifications={setNotifications}
        >
          {children}
        </ContentContainer>
      </div>
    </Box>
  );
};
