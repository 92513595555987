import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import astrounautHoldingPhone from "../../assets/posesPNG/astronout-holding-phone.png";
import useIsMobileDevice from "../../utils/mobileCheck";

export const MobileModal = ({}) => {
  const [supportModal, setSupportModal] = useState(false);

  useEffect(() => {
    const hasSeenMobile = JSON.parse(
      localStorage.getItem("@ecomlovers:hasSeenMobileModal")
    );

    if (!hasSeenMobile && !supportModal) {
      setSupportModal(true);
      localStorage.setItem("@ecomlovers:hasSeenMobileModal", false);
    }
  }, []);

  const isMobile = useIsMobileDevice();

  const handleMobileModal = () => {
    setSupportModal(false);
    localStorage.setItem("@ecomlovers:hasSeenMobileModal", true);
  };

  if (supportModal && !isMobile)
    return (
      <Modal
        disableAutoFocus
        open
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setSupportModal("false");
          }
        }}
      >
        <Box
          style={{
            background: "#ffffffff",
            borderRadius: "20px",
          }}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            // height: 200,
            p: 5,
            border: "3px solid #EC5569",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  color={"#EC5569"}
                  fontSize={20}
                  fontWeight={600}
                  textAlign={"center"}
                  marginBottom={"15px"}
                >
                  {"Experiência Otimizada no Mobile"}
                </Typography>

                <IconButton
                  onClick={() => handleMobileModal()}
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                >
                  <Close style={{ color: "#EC5569" }} />
                </IconButton>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  color={"#57BAB8"}
                  fontSize={18}
                  fontWeight={400}
                  textAlign={"center"}
                  marginBottom={"15px"}
                >
                  {
                    "Para uma melhor experiência de navegação, recomendamos o uso de um navegador mobile. Alguns recursos podem não funcionar corretamente em outros dispositivos. Agradecemos sua compreensão!"
                  }
                </Typography>
              </div>
              <Button
                color="primary"
                sx={{
                  marginTop: "15px",
                  backgroundColor: "#EC5569",
                  borderRadius: "80px",
                  color: "white",
                  fontSize: "16px",
                  width: "300px",
                  alignSelf: "center",
                  ":hover": {
                    color: "#EC5569",
                  },
                }}
                variant="outlined"
                style={{ textTransform: "none" }}
                onClick={() => handleMobileModal()}
              >
                Continuar assim mesmo
              </Button>
            </div>

            <img
              src={astrounautHoldingPhone}
              style={{
                height: "230px",
                minWidth: "230px",
                padding: "25px",
                objectFit: "contain",
              }}
            />
          </div>
        </Box>
      </Modal>
    );
};
