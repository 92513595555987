import AWS from "aws-sdk";
import moment from "moment";
import CryptoJS from "crypto-js";

const hashFile = async (filePath) => {
  try {
    const fileBuffer = await fetch(filePath).then(res => res.arrayBuffer());
    const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(fileBuffer));
    const hash = CryptoJS.MD5(wordArray);
    const hexDigest = CryptoJS.enc.Base64.stringify(hash);
    return hexDigest;
  } catch (error) {
    console.error("Erro ao calcular o hash:", error);
    throw error;
  }
};

const S3_BUCKET = 'ecom-lovers';
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET,
});

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const MULTIPART_THRESHOLD = 5 * 1024 * 1024; // 5MB
const PART_SIZE = 5 * 1024 * 1024; // 5MB

export const uploadFile = async (file, onProgress) => { // Recebe a função onProgress para atualizar o progresso
  try {
    const hashKey = `ecom-lovers-${moment(new Date()).format("MMMDDYYYY-HHmmss")}`;
    
    const checksum = await hashFile(file);

    let uploadResult;

    if (file.size > MULTIPART_THRESHOLD) {
      console.log("Iniciando upload multipart...");
      const multipartUpload = await s3.createMultipartUpload({
        Bucket: S3_BUCKET,
        Key: hashKey,
        ContentType: file.type
      }).promise();

      const uploadId = multipartUpload.UploadId;
      console.log("Upload ID: ", uploadId);

      const uploadedParts = [];

      try {
        const numberOfParts = Math.ceil(file.size / PART_SIZE);
        console.log(`Total de partes para upload: ${numberOfParts}`);

        for (let i = 0; i < numberOfParts; i++) {
          const start = i * PART_SIZE;
          const end = Math.min(start + PART_SIZE, file.size);
          const chunk = file.slice(start, end);

          const chunkBlob = new Blob([chunk], { type: file.type });

          console.log(`Iniciando upload da parte ${i + 1}`);

          const requestParams = {
            Body: chunkBlob,
            Bucket: S3_BUCKET,
            Key: hashKey,
            PartNumber: i + 1,
            UploadId: uploadId
          };

          try {
            const partResponse = await s3.uploadPart(requestParams).promise();
            if (partResponse && partResponse.ETag) {
              console.log(`Parte ${i + 1} upload com sucesso. ETag:`, partResponse.ETag);
            } else {
              throw new Error(`Erro: ETag não retornado para a parte ${i + 1}`);
            }

            uploadedParts.push({
              PartNumber: i + 1,
              ETag: partResponse.ETag
            });

            const progress = Math.round(((i + 1) * PART_SIZE / file.size) * 100);
            onProgress(progress); // Atualiza o progresso aqui
          } catch (partError) {
            console.error(`Erro ao fazer upload da parte ${i + 1}:`, partError);
            throw partError;
          }
        }

        uploadResult = await s3.completeMultipartUpload({
          Bucket: S3_BUCKET,
          Key: hashKey,
          UploadId: uploadId,
          MultipartUpload: { Parts: uploadedParts }
        }).promise();

      } catch (err) {
        console.error('Erro durante o upload multipart:', err);
        await s3.abortMultipartUpload({
          Bucket: S3_BUCKET,
          Key: hashKey,
          UploadId: uploadId
        }).promise();
        throw err;
      }
    } else {
      uploadResult = await s3.upload({
        Bucket: S3_BUCKET,
        Key: hashKey,
        Body: file,
        ContentType: file.type
      }).on('httpUploadProgress', (evt) => {
        const progress = Math.round((evt.loaded * 100) / evt.total);
        onProgress(progress); // Atualiza o progresso no upload direto
      }).promise();
    }

    return {
      file: {
        filename: file.name,
        byte_size: file.size,
        checksum: checksum,
        key: hashKey,
        content_type: file.type,
        location: uploadResult.Location
      }
    };

  } catch (error) {
    console.error("Erro no upload:", error);
    throw error;
  }
};
