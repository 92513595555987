import React from "react";
import Slider from "react-slick";

import astro1 from "../../../assets/posesPNG/astronout-standing-ringlight.png";
import astro2 from "../../../assets/posesPNG/astronout-sitting-notebook.png";
import astro3 from "../../../assets/posesPNG/astronout-standing-sign.png";
import astro4 from "../../../assets/posesPNG/astronout-tpose-front.png";
import astro5 from "../../../assets/posesPNG/astronout-holding-phone.png";
import astro6 from "../../../assets/posesPNG/astronout-standing-headphone.png";
import astro7 from "../../../assets/posesPNG/astronout-riding-rocket.png";

import "./CenterMode.css";

function CenterMode() {
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false, // Remover as setas
    // autoplay: true,
    // speed: 2000,
    // autoplaySpeed: 2000,
    // cssEase: "linear"
  };

  const infoPassos = [
    {
      title: "Ambiente é Tudo!",
      content: [
        "Escolha um local tranquilo e bem iluminado para gravar seu vídeo.",
        "Nada de luz fraquinha, hein? E, antes de começar, não se esqueça de verificar se a lente do seu dispositivo está limpinha!",
      ],
      img: astro1,
      number: 1,
    },
    {
      title: "Seja você, mas…",
      content: [
        "Prepare um roteiro simples! Pense nos principais pontos que deseja destacar, como suas experiências e suas super habilidades.",
        "Escreva em tópicos apenas para lembrar.",
        "Seja natural, deixa fluir…",
      ],
      img: astro2,
      number: 2,
    },
    {
      title: "Sorria, você está... ",
      content: [
        "Olhe diretamente para a bolinha preta e mostre seu entusiasmo!",
        "É estranho ficar olhando fixo para câmera, mas para quem assiste, cria uma conexão, entende?",
        "E o seu sorriso pode realmente te destacar!",
      ],
      img: astro3,
      number: 3,
    },
    {
      title: "And the Oscar goes to…",
      content: [
        "Faça alguns ensaios antes de gravar a versão final.",
        "Isso ajuda a ajustar o tom e garantir que você esteja dentro do tempo limite.",
        "Mas não espere ficar perfeito! Você pode voltar aqui e atualizar quando quiser.",
      ],
      img: astro4,
      number: 4,
    },
    {
      title: "Deita a tela!",
      content: [
        "Os recrutadores vão ver o seu vídeo em um computador, então, grave na posição horizontal (modo paisagem, 16:9, vira o celular…).",
        "Garanta que você apareça no centro do vídeo e da cintura para cima.",
      ],
      img: astro5,
      number: 5,
    },
    {
      title: "Checa tudo!",
      content: [
        "Volume? Tá ouvindo bem? Barulho externo? Algum cachorro latiu por aí? Cortou alguma fala? Passou dos 2 minutos?",
        "Não! Então vamos lá!",
        "Sua Selfie Letter vai ficar incrível com as suas revisões.",
      ],
      img: astro6,
      number: 6,
    },
    {
      title: "É só falar bora! Que eu boro!",
      content: [
        "Quando estiver pronto, suba sua Selfie Letter no botão abaixo. E depois?",
        "É só esperar as mensagens dos recrutadores interessados no seu perfil, subir no foguete e decolar para sua próxima jornada!",
      ],
      img: astro7,
      number: 7,
    },
  ];

  return (
    <div className="slider-container">
      <Slider {...settings} className="containerSlide">
        {infoPassos.map((slide, index) => (
          //   <div className="slider-item" key={index}>
          <div className="card">
            <div className="index">{slide.number}</div>
            <div className="containerImage">
              <img src={slide.img} height={"102px"} alt="astronauta" />
            </div>
            <div className="card-body">
              <div className="title">{slide.title}</div>
              {slide.content.map((text, idx) => (
                <div key={idx} className="subtitle">
                  {text}
                </div>
              ))}
            </div>
          </div>
          //   </div>
        ))}
      </Slider>
    </div>
  );
}

export default CenterMode;
