import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React, { useContext } from "react";

import logo from "../../assets/logo.svg";
import { MainListItems } from "./listItems";
import { Help } from "@mui/icons-material";
import { AuthContext } from "../../providers/AuthProvider";

export const MainSidebarCompanies = () => {

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: '10px',
      }}
    >
        <MainListItems />
    </div>
  );
};
