import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import useIsMobileDevice from "../../../utils/mobileCheck";
import systemIcon from "../../../assets/systemIcon.svg";
import checkIcon from "../../../assets/Vector (13).png";
import LayoutSpace from "../../components/LayoutSpace";
import api from "../../../utils/api";
import "./Checkout.css";
import { Typography } from "@mui/material";
import unmask from "../../../utils/unmask";
import cardBrand from "../../../utils/cardBrand";
import StripeWrapper from "../../../utils/StripeWrapper";

const Checkout = ({ authToken }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobileDevice();
  const [currentScreen, setCurrentScreen] = useState("screen1");
  const [loading, setLoading] = useState(true);
  const [listPlans, setListPlans] = useState([]);
  const [cards, setCards] = useState([]);
  const [loadingCards, setLoadingCards] = useState(true);
  const [selectedCard, setSelectedCard] = useState({});
  const [saveCard, setSaveCard] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [planoSelected, setPlanoSelected] = useState();

  const stylesContainer = currentScreen !== "screen1" && {
    alignItems: "start",
  };

  const stylesImage = currentScreen !== "screen1" && { height: "75vh" };

  const getPLans = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/companies/payment_plans`,
        authToken && {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (data.data) {
        setListPlans(data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPLans();
  }, []);

  console.log(listPlans);

  const formLogin = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    // onSubmit: (values) => handleContinue(values),
  });

  const step2Form = useFormik({
    initialValues: {
      cardFullName: "",
      cardNumber: "",
      cardExpirationDate: "",
      cardCVV: "",
    },
    validationSchema: yup.object({
      cardFullName: yup
        .string()
        .required("Nome no cartão é obrigatório")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      cardNumber: yup
        .string()
        .required("Número do cartão é obrigatório")
        .matches(
          /^[0-9\s]{19}$/,
          "Número do cartão deve estar no formato 9999 9999 9999 9999"
        ),
      cardExpirationDate: yup
        .string()
        .required("Data de expiração é obrigatória")
        .matches(
          /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
          "Data de expiração deve estar no formato MM/AA"
        )
        .test("expDate", "Data de expiração inválida", (value) => {
          if (!value) return false;
          const today = new Date();
          const [month, year] = value.split("/").map(Number);
          const expDate = new Date(`20${year}`, month - 1);
          return expDate >= today;
        }),
      cardCVV: yup
        .string()
        .required("CVV é obrigatório")
        .matches(/^[0-9]{3,4}$/, "CVV deve ter 3 dígitos"),
    }),
    onSubmit: () => registerCard(),
  });

  const getCards = async () => {
    setLoadingCards(true);
    try {
      const { data: cards } = await api.get(`/partners/credit_cards`);

      if (cards) {
        //console.log(cards.data);
        setCards(
          cards.data.map((item, index) => {
            return {
              id: item.id,
              position: index,
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados dos cartões.");
    } finally {
      setLoadingCards(false);
    }
  };

  const registerCard = async () => {
    setSaveCard(true);
    try {
      const { data } = await api.post("/companies/credit_cards", {
        data: {
          attributes: {
            cardNumber: step2Form.values.cardNumber,
            cardHolderName: step2Form.values.cardFullName,
            expiresAt:
              "20" +
              unmask(step2Form.values.cardExpirationDate).slice(2, 4) +
              "-" +
              unmask(step2Form.values.cardExpirationDate).slice(0, 2),
            cardCvv: step2Form.values.cardCVV,
            brand: cardBrand(step2Form.values.cardNumber),
          },
        },
      });

      if (data) {
        // setModal(false);
        // step2Form.setValues(step2Form.initialValues);
        setSelectedCard(data.data);
        getCards();
        navigate("/financeiro/step3");
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors[0].title) {
        toast.error(error.response.data.errors[0].title);
      }
      return toast.error("Ocorreu um erro ao tentar cadastrar o cartão.");
    } finally {
      setSaveCard(false);
    }
  };

  const list = [
    "Perfil Business",
    "Acesso a base de talentos",
    "Filtro de Talentos",
    "Filtro de Talentos",
    "Mensageria",
    "Suporte",
  ];

  const planosList = [
    {
      name: "Padrão",
      subtitle:
        "Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui.",
      value: 89.9,
      list: [
        "Perfil Business",
        "Acesso a base de talentos",
        "Filtro de Talentos",
        "Filtro de Talentos",
        "Mensageria",
        "Suporte",
      ],
    },
    {
      name: "Lovers",
      subtitle:
        "Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui.",
      value: 169.9,
      list: [
        "Todos os itens da versão Padrão",
        "Filtro de Talentos Avançado",
        "Lista de Favoritos",
        "Suporte Whatsapp",
        "",
        "",
        "",
        "",
      ],
    },
    {
      name: "Lovers Plus",
      subtitle:
        "Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui.",
      value: 269.6,
      list: [
        "Todos os itens da versão Padrão",
        "Filtro de Talentos Avançado",
        "Lista de Favoritos",
        "Suporte Whatsapp",
        "",
        "",
        "",
        "",
      ],
    },
  ];

  const PaymentScreen = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    console.log(planoSelected);

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.protocol}//${window.location.host}/companies/registro/completar`,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }

      setIsLoading(false);
    };

    return (
      <div className="containerPlans">
        <div className="containerMessage">
          <div>
            <img
              src={systemIcon}
              alt={""}
              style={{
                width: isMobile ? "75px" : "100px",
                height: isMobile ? "75px" : "100px",
                marginRight: "10px",
              }}
            />
          </div>

          <Typography
            fontWeight={500}
            sx={{
              border: "1px solid #FFE4E5",
              backgroundColor: "#FFE4E5",
              color: "black",
              borderRadius: "20px",
              padding: "25px",
              display: "flex",
              width: "100%",
            }}
          >
            {`Você escolheu o plano ${planoSelected.name}, confira e finalize a sua contratação, este ambiente é seguro para pagamentos`}
          </Typography>
        </div>
        <div className="containerCardCheckout">
          <div className="cardPLans" style={{ width: "100%" }}>
            <div className="title">{planoSelected.name}</div>

            <div className="value">
              <div className="subTitle">R$</div>
              <div>{planoSelected.value}</div>
            </div>
            <div className="listItens" style={{ gap: "28px" }}>
              {planoSelected.list?.map((item, idx) => (
                <div className="list">
                  {item !== "" && (
                    <div className="img">
                      <img src={checkIcon} alt="check icon" />
                    </div>
                  )}
                  <div key={idx}>{item}</div>
                </div>
              ))}
            </div>
          </div>

          <form className="containerCreditCard">
            {/* <div className="card">
              <div className="containerInput">
                <div className="title">Nome que está no cartão</div>
                <input
                  className="inputCredCard"
                  type="text"
                  name="cardFullName"
                  onChange={step2Form.handleChange}
                  onBlur={step2Form.handleBlur}
                  value={step2Form.values.cardFullName}
                />
                {step2Form.touched.cardFullName &&
                step2Form.errors.cardFullName ? (
                  <div className="error">{step2Form.errors.cardFullName}</div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="title">Número do Cartão</div>
                <InputMask
                  className="inputCredCard"
                  mask="**** **** **** 9999"
                  name="cardNumber"
                  onChange={step2Form.handleChange}
                  onBlur={() => {
                    step2Form.setFieldTouched("cardNumber", true);
                  }}
                  value={step2Form.values.cardNumber}
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>
                {step2Form.touched.cardNumber && step2Form.errors.cardNumber ? (
                  <div className="error">{step2Form.errors.cardNumber}</div>
                ) : null}
              </div>
            </div>

            <div className="card" style={{ flexDirection: "row" }}>
              <div className="containerInput">
                <div className="title">Data de validade</div>
                <InputMask
                  className="inputCredCard"
                  mask="99/99"
                  name="cardExpirationDate"
                  onChange={step2Form.handleChange}
                  onBlur={step2Form.handleBlur}
                  value={step2Form.values.cardExpirationDate}
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>
                {step2Form.touched.cardExpirationDate &&
                step2Form.errors.cardExpirationDate ? (
                  <div className="error">
                    {step2Form.errors.cardExpirationDate}
                  </div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="title">Código de Segurança</div>
                <InputMask
                  className="inputCredCard"
                  mask="999"
                  name="cardCVV"
                  onChange={step2Form.handleChange}
                  onBlur={step2Form.handleBlur}
                  value={step2Form.values.cardCVV}
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>
                {step2Form.touched.cardCVV && step2Form.errors.cardCVV ? (
                  <div className="error">{step2Form.errors.cardCVV}</div>
                ) : null}
              </div>
            </div>

            <div className="containerInput">
              <div className="title">CPF/CNPJ</div>
              <input
                className="inputCredCard"
                type="text"
                name="cardFullName"
                onChange={step2Form.handleChange}
                onBlur={step2Form.handleBlur}
                value={step2Form.values.cardFullName}
              />
              {step2Form.touched.cardFullName &&
              step2Form.errors.cardFullName ? (
                <div className="error">{step2Form.errors.cardFullName}</div>
              ) : null}
            </div>*/}

            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            {stripe && elements && (
              <button onClick={handleSubmit} disabled={isLoading}>
                Comece agora
              </button>
            )}
          </form>
        </div>
      </div>
    );
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case "screen1":
        return (
          <div className="containerPlans">
            <div className="containerMessage">
              <div>
                <img
                  src={systemIcon}
                  alt={""}
                  style={{
                    width: isMobile ? "75px" : "100px",
                    height: isMobile ? "75px" : "100px",
                    marginRight: "10px",
                  }}
                />
              </div>

              <Typography
                fontWeight={500}
                sx={{
                  border: "1px solid #FFE4E5",
                  backgroundColor: "#FFE4E5",
                  color: "black",
                  borderRadius: "20px",
                  padding: "25px",
                  display: "flex",
                  width: "100%",
                }}
              >
                Já temos informações o suficiente para te oferecer o melhor fit
                para solucionar os seus problemas!
              </Typography>
            </div>
            <div className="containerCardPlans">
              {planosList.map((item, idx) => (
                <div
                  className="cardPLans"
                  key={idx}
                  style={{ backgroundColor: idx % 2 !== 0 && "#BEFCF9" }}
                >
                  <div className="title">{item.name}</div>
                  <div className="subTitle">{item.subtitle}</div>

                  <div className="value">
                    <div className="subTitle">R$</div>
                    <div>{item.value}</div>
                  </div>

                  <button
                    onClick={() => {
                      setCurrentScreen("screen2");
                      setPlanoSelected(item);
                    }}
                  >
                    Comece agora
                  </button>
                  <div className="listItens">
                    {item.list?.map((item, idx) => (
                      <div className="list">
                        {item !== "" && (
                          <div className="img">
                            <img src={checkIcon} alt="check icon" />
                          </div>
                        )}
                        <div key={idx}>{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return (
          <StripeWrapper plan={1} authToken={authToken}>
            <PaymentScreen />
          </StripeWrapper>
        );
    }
  };

  const joinStrings = (strings) => {
    return strings.join("");
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return <>{renderScreen(currentScreen)}</>;
  // return (
  //
  // );
};

export default Checkout;
