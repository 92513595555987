import React from "react";
import "./TokenInput.css";

const TokenInput = ({ token, setToken }) => {
  const handleChange = (e, index) => {
    const value = e.target.value;
    const newToken = [...token];

    if (value.length > 1) {
      const splitValues = value.split("");
      splitValues.forEach((char, i) => {
        if (index + i < token.length) {
          newToken[index + i] = char;
        }
      });
      setToken(newToken);

      const nextIndex = index + splitValues.length - 1;
      if (nextIndex < token.length) {
        setTimeout(() => {
          document.getElementById(`token-input-${nextIndex}`).focus();
        }, 0);
      }
    } else {
      newToken[index] = value;
      setToken(newToken);

      if (value && index < token.length - 1) {
        setTimeout(() => {
          document.getElementById(`token-input-${index + 1}`).focus();
        }, 0);
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !token[index] && index > 0) {
      document.getElementById(`token-input-${index - 1}`).focus();
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    const newToken = [...token];
    const splitValues = pastedData.split("");
    splitValues.forEach((char, i) => {
      if (index + i < token.length) {
        newToken[index + i] = char;
      }
    });

    setToken(newToken);

    const nextIndex = index + splitValues.length - 1;
    if (nextIndex < token.length) {
      setTimeout(() => {
        document.getElementById(`token-input-${nextIndex}`).focus();
      }, 0);
    }
  };

  return (
    <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
      {token.map((value, index) => (
        <input
          key={index}
          id={`token-input-${index}`}
          type="text"
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          maxLength="1"
          className="inputToken"
        />
      ))}
    </div>
  );
};

export default TokenInput;
