import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import { Loader } from "../../../components/Loader";
import { AuthContext } from "../../../providers/AuthProvider";
import astronauta from "../../../assets/image 16.png";
import astro2 from "../../../assets/image 15.png";
import api from "../../../utils/api";

import "./Screen2.css";
import { Button } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

const Screen2 = ({ setCurrentScreen }) => {
  // const navigate = useNavigate();
  const { user, updateUser } = useContext(AuthContext);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const getQuestions = async () => {
    setLoadingQuestions(true);
    try {
      const { data } = await api.get(`/questions/cultural_fit_questions`);
      const mapQuestions = data?.data.map((item) => ({
        id: item.id,
        ...item.attributes,
      }));
      setQuestions(mapQuestions);
      setLoadingQuestions(false);
    } catch (error) {
      setLoadingQuestions(false);
    }
  };

  const getQuestions2 = async () => {
    try {
      const { data } = await api.get(`/questions/questions`);
    } catch (error) {}
  };

  useEffect(() => {
    getQuestions();
    getQuestions2();
  }, []);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const savedQuestionIndex = localStorage.getItem("currentQuestionIndex");
    return savedQuestionIndex !== null ? Number(savedQuestionIndex) : 0;
  });

  const [responses, setResponses] = useState(() => {
    try {
      const savedResponses = localStorage.getItem("responsesFit");
      return savedResponses ? JSON.parse(savedResponses) : [];
    } catch (error) {
      console.error("Erro ao carregar as mensagens:", error);
      return []; // Retorna uma lista vazia caso haja erro no parsing
    }
  });

  const [completeQuestions, setCompleteQuestions] = useState(false);

  const handleOptionChange = (questionId, optionId) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: optionId,
    }));
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      localStorage.setItem("currentQuestionIndex",currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      localStorage.setItem("currentQuestionIndex",currentQuestionIndex - 1);
    }
  };

  const handleSubmit = () => {
    console.log("Respostas salvas:", responses);
    // Aqui você pode enviar as respostas para um servidor ou processá-las conforme necessário
  };

  const currentQuestion = questions[currentQuestionIndex];

  const nextQuestions = async () => {
    setLoading(true);
    try {
      const { data } = await api.post(
        `/talents/cultural_fit/answer_questions`,
        {
          data: {
            attributes: {
              questionId: currentQuestionIndex + 1,
              answerOption: responses[currentQuestionIndex + 1],
            },
          },
        }
      );

      if (currentQuestionIndex === 24) {
        setCompleteQuestions(true);
        updateUser(data);
        localStorage.removeItem("responsesFit");
        localStorage.removeItem("currentQuestionIndex");
        // setCurrentQuestionIndex(questions.lengt + 1);
      } else {
        handleNext();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toast.error("Por favor, selecione uma resposta.");
    }
  };

  useEffect(() => {
    localStorage.setItem("responsesFit", JSON.stringify(responses));
  }, [responses]);

  return (
    <>
      {loadingQuestions ? (
        <div className="containerLoader">
          <Loader />
        </div>
      ) : (
        <div className="containerScreen2" style={{ marginTop: "20px" }}>
          {completeQuestions ? (
            <>
              <div className="bar">
                <div className="fill">
                  <span
                    className="leads"
                    style={{
                      width: `${(
                        (currentQuestionIndex / (questions.length - 1)) *
                        100
                      ).toFixed(2)}%`,
                    }}
                  ></span>
                </div>
              </div>
              <div className="cardCenter2">
                <img src={astronauta} alt="astronauta" />
                <div className="title" style={{ width: "214px" }}>
                  É isso ai, seu Fit Cultural está pronto!
                </div>
                <div className="subttile">
                  Obrigado por compartilhar suas perspectivas conosco. Suas
                  respostas nos ajudarão a entender como podemos trabalhar
                  melhor juntos.
                </div>

                <div
                  className={`buttonRemove ${loading && "loading"}`}
                  style={{ fontWeight: "500", width: "90%" }}
                  onClick={() => setCurrentScreen("screen1")}
                >
                  Clique aqui para voltar e visualizar
                </div>
              </div>
            </>
          ) : (
            <>
                <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
                marginTop: '10px'
              }}
            >
              <Button
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
                color="secondary"
                style={{ textTransform: "none" }}
                onClick={() => handlePrevious()}
                disabled={currentQuestionIndex <= 0}
              >
                <ArrowBackIos
                  sx={{
                    width: "15px",
                    height: "15px",
                    color: "white",
                    padding: "5px",
                    backgroundColor: currentQuestionIndex <= 0 ? "#f7f7f7" : "#57BAB8",
                    borderRadius: "5px",
                    paddingLeft: "10px",
                  }}
                />
                <div className="textSkil" style={{ color: currentQuestionIndex <= 0 && "#f7f7f7"}}>Voltar</div>
              </Button>
            </div>
              <div className="bar">
  
                <div className="fill">
                  <span
                    className="leads"
                    style={{
                      width: `${(
                        (currentQuestionIndex / (questions.length - 1)) *
                        100
                      ).toFixed(2)}%`,
                    }}
                  ></span>
                </div>
              </div>
              
        

              <div className="cardCenter">
                <div className="title">{currentQuestion?.questionText}</div>
                {/* <div className="subttile">{currentQuestion?.questionText}</div> */}

                {currentQuestion?.options.map((option) => (
                  <label key={option.id} className="containerQuestions">
                   <div className="radioContainer">
                    <input
                        type="radio"
                        name={`question-${currentQuestion?.id}`}
                        value={option.option_id}
                        onChange={() =>
                          handleOptionChange(
                            currentQuestion?.id,
                            option.option_id
                          )
                        }
                        checked={
                          responses[currentQuestion?.id] === option.option_id
                        }
                        className="radio"
                      />
                   </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      {option.option_text}
                    </div>
                  </label>
                ))}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  {/* {currentQuestionIndex > 0 && (
            <button type="button" onClick={handlePrevious}>
              Anterior
            </button>
          )}
          <button type="button" onClick={handleNext}>
            {currentQuestionIndex < questions.length - 1
              ? "Próximo"
              : "Finalizar"}
          </button> */}
                  <div
                    className={`buttonRemove ${loading && "loading"}`}
                    style={{ fontWeight: "700" }}
                    onClick={() => nextQuestions()}
                  >
                    {loading ? "Enviando..." : "Responder e continuar"}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Screen2;
