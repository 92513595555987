import React from "react";
import bakcgroundIMage from "../../assets/pixelcut-export 1.png";
import logo from "../../assets/ecomlovers-logo-transparente.svg";

import "./LayoutSpace.css";

const LayoutSpace = ({ children, stylesContainer, stylesImage }) => {
  return (
    <div className="containerLayoutSpace" style={{ ...stylesContainer }}>
      <img
        src={bakcgroundIMage}
        alt="background"
        className="containerBackground"
        style={{ ...stylesImage }}
      />
      <div className="containerGeralItens">
        <div>
          <img src={logo} alt="logo" className="logoImg" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default LayoutSpace;
