// AstronautAnimation.js
import React from "react";
import "./AstronautAnimation.css"; // Arquivo de estilo para animação
import astronautImage from "../../assets/image 18.svg";

function AstronautAnimation() {
  return (
    <div className="space-container">
      <div className="stars"></div>
      <img src={astronautImage} alt="Astronauta" className="astronaut" />
    </div>
  );
}

export default AstronautAnimation;
