import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../../assets/Logo (1).png";
import sendIcon from "../../assets/Artboard 3 copy 7.svg";

import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AuthContext } from "../../providers/AuthProvider";
import useIsMobileDevice from "../../utils/mobileCheck";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import selectIcon from "../../assets/Frame 20 (6).png";
import selectedIcon from "../../assets/Frame 20 (5).png";
import "./index.css";
import { brazilStates } from "../../utils/states";
import { getBase64, removeAfterColon } from "../../utils";
import NewSlider2 from "./components/NewSlider2";

import "./SignUp.css";
import { toast } from "react-toastify";
import axios from "axios";

export const SignUp = () => {
  // const [loading, setLoading] = useState(false);
  const [loading, setLoading] = useState(() => {
    return localStorage.getItem("loading") || false;
  });

  const {
    signUp,
    handleQuestion,
    handleAnswer,
    handleAnswerFile,
    handleAnswerKnowledge,
    handleAnswerAreas,
  } = useContext(AuthContext);
  const isMobile = useIsMobileDevice();
  // const [questionIndex, setQuestionIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(() => {
    const savedQuestionIndex = localStorage.getItem("questionIndex");
    return savedQuestionIndex !== null ? Number(savedQuestionIndex) : 0;
  });

  const [idState, setdState] = useState(() => {
    const savedQuestionIndex = localStorage.getItem("idState");
    return savedQuestionIndex !== null ? Number(savedQuestionIndex) : null;
  });

  const navigate = useNavigate();

  const [nextQuestion, setNextQuestion] = useState(() => {
    const savedNextQuestion = localStorage.getItem("nextQuestion");
    try {
      return savedNextQuestion
        ? JSON.parse(savedNextQuestion)
        : savedNextQuestion === "undefined"
        ? undefined
        : [];
    } catch (error) {
      console.error("Erro ao carregar as mensagens:", error);
      return savedNextQuestion === "undefined" ? undefined : []; // Retorna uma lista vazia caso haja erro no parsing
    }
  });

  const [comment, setComment] = useState(() => {
    return localStorage.getItem("comment") || "";
  });
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const [authToken, setAuthToken] = useState(() => {
    return localStorage.getItem("authToken") || "";
  });
  const hasRunEffect = useRef(false);
  const [fieldType, setFieldType] = useState(() => {
    return localStorage.getItem("fieldType") || "text";
  });
  const [loadingMessage, setLoadingMessage] = useState(false);

  const inputRef = useRef(null);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [tempPhone, setTempPhone] = useState("");

  const [selectKnowledge, setSelectedKnowledge] = useState(() => {
    try {
      const savedKnowledge = localStorage.getItem("selectKnowledge");
      return savedKnowledge ? JSON.parse(savedKnowledge) : [];
    } catch (error) {
      console.error("Erro ao carregar as mensagens:", error);
      return []; // Retorna uma lista vazia caso haja erro no parsing
    }
  });

  const [selectArea, setSelectedArea] = useState(() => {
    try {
      const savedArea = localStorage.getItem("selectArea");
      return savedArea ? JSON.parse(savedArea) : [];
    } catch (error) {
      console.error("Erro ao carregar as mensagens:", error);
      return []; // Retorna uma lista vazia caso haja erro no parsing
    }
  });
  // const [userEmail, setUserEmail] = useState("");
  const [userEmail, setUserEmail] = useState(() => {
    return localStorage.getItem("userEmail") || "";
  });

  const [isConversationStarted, setIsConversationStarted] = useState(() => {
    return localStorage.getItem("isConversationStarted") === "true"; // Converte a string para booleano
  });

  useEffect(() => {
    if (loading) {
      setHasLoadedOnce(true);
    }
  }, [loading]);

  useEffect(() => {
    if (hasLoadedOnce && !loading && inputRef.current) {
      inputRef.current.select();
    }
  }, [loading, hasLoadedOnce]);

  const handleResize = () => {
    scrollToBottom();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [messages, setMessages] = useState(() => {
    try {
      const savedMessages = localStorage.getItem("chatMessages");
      return savedMessages ? JSON.parse(savedMessages) : [];
    } catch (error) {
      console.error("Erro ao carregar as mensagens:", error);
      return []; // Retorna uma lista vazia caso haja erro no parsing
    }
  });

  // Remover mensagens duplicadas
  useEffect(() => {
    const uniqueMessages = Array.from(
      new Map(
        messages.map((msg) => [`${msg.message}-${msg.sender}`, msg])
      ).values()
    );

    if (uniqueMessages.length !== messages.length) {
      setMessages(uniqueMessages);
    }
  }, [messages]);

  const [questions, setQuestions] = useState([
    "Bem-vindo! Estamos felizes em tê-lo na nossa comunidade de apaixonados por eCommerce e o mundo digital.",
  ]);

  const validateBirthdate = Yup.date()
    .required("A data é obrigatória")
    .transform((value, originalValue) => {
      const dataNew = moment(originalValue).format("DD/MM/YYYY");
      const parsedDate = moment(dataNew, "DD/MM/YYYY", true);
      return parsedDate.isValid() ? parsedDate.toDate() : new Date("");
    })
    .test("is-18-or-older", function (value) {
      if (!value || isNaN(value.getTime()))
        return this.createError({ message: "Data inválida" });

      const age = moment().diff(moment(value), "years");

      if (age < 18) {
        const yearsLeft = 18 - age;
        return this.createError({
          message: `Falta(m) ${yearsLeft} ano(s) para seus 18 anos! Queremos você aqui conosco!`,
        });
      }
      return true;
    });

  const validatePhone = Yup.string()
    .matches(
      /^\+55 \(\d{2}\) 9 \d{4}-\d{4}$/,
      "O número de telefone é inválido"
    )
    .required("O telefone é obrigatório");

  const validateEmail = Yup.string()
    .required("O e-mail é obrigatório")
    .email("Formato de e-mail inválido");

  const validateLinkedInUrl = Yup.string()
    .required("O link do LinkedIn é obrigatório")
    .matches(
      /^(https?:\/\/)?(www\.)?(linkedin\.com\/.*)$/,
      "O link deve ser um URL válido do LinkedIn"
    );

  const validationSchema = Yup.object().shape({
    field: Yup.lazy((value) => {
      switch (nextQuestion?.fieldText) {
        case "birthdate":
          return validateBirthdate;
        case "phone":
          return validatePhone;
        case "email":
          return validateEmail;
        case "linkedin_url":
          return validateLinkedInUrl;
        default:
          return Yup.string().required("");
      }
    }),
  });

  const calculateEighteenYearsOldDate = () => {
    const today = moment();
    return today.subtract(18, "years").format("YYYY-MM-DD"); // Formato padrão para inputs de tipo "date"
  };

  const form = useFormik({
    initialValues: {
      field: null,
    },
    validationSchema,
    onSubmit: (values) => {
      // Verificação se campo está vazio para tipo 'select'
      handleUserAnswer(values.field);
      form.setValues(form.initialValues);
    },
  });

  // useEffect(() => {
  //   if(nextQuestion?.fieldText === "birthdate") {
  //     form.setFieldTouched("field", calculateEighteenYearsOldDate());
  //   }
  // },[nextQuestion?.fieldText])

  useEffect(() => {
    if (fieldType === "masked") {
      setTempPhone(form.values.field);
    }
    if (fieldType === "file" && form.values !== form.initialValues) {
      form.submitForm();
    }
  }, [form.values.field]);

  useEffect(() => {
    if (!hasRunEffect.current && !isConversationStarted) {
      hasRunEffect.current = true;
      handleInitialMessage();
    }
  }, []);

  useEffect(() => {
    if (nextQuestion?.questionText) {
      handleEffect();
    } else {
      handleComment();
      setLoading(false);
    }
    if (nextQuestion?.knowledgeArea !== "talent_knowledge_area") {
      setSelectedArea(nextQuestion?.options);
      localStorage.setItem("selectArea", JSON.stringify(nextQuestion?.options));
    }
  }, [nextQuestion]);

  const handleEffect = async () => {
    await handleComment();
    await handleNextQuestion();
  };

  const handleInitialMessage = async () => {
    await systemMessage(questions[0]);
    setQuestionIndex(1);
    localStorage.setItem("isConversationStarted", "true");
    const { data } = await handleQuestion();
    setNextQuestion(data[0].attributes);
    localStorage.setItem("nextQuestion", JSON.stringify(data[0].attributes));
  };

  const handleComment = async () => {
    if (comment) {
      await systemMessage(comment);
    }
  };

  const handleFieldType = (question) => {
    if (question?.fieldText) {
      switch (question.fieldText) {
        default:
          return "text";
        case "birthdate":
          return "date";
        case "state":
          return "select";
        case "city":
          return "selectCity";
        case "phone":
          return "masked";
        case "avatar":
          return "file";
      }
    } else {
      if (question?.knowledgeArea === "talent_knowledge_area") {
        return "selectKnowledge";
      } else {
        return "grade";
      }
    }
  };

  const handleNextQuestion = async () => {
    await systemMessage(nextQuestion?.questionText);
    setLoading(false);
    setQuestionIndex(nextQuestion?.questionOrder);
    localStorage.setItem("questionIndex", nextQuestion?.questionOrder);
  };

  const systemMessage = async (text) => {
    setLoadingMessage(true);
    await delay(1500);
    setMessages((prevMessages) => [
      ...prevMessages,
      { message: text, sender: "system" },
    ]);
    scrollToBottom();
    setLoadingMessage(false);
  };

  const userMessage = async (text) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { message: text, sender: "talent" },
    ]);
    scrollToBottom();
  };

  const resetLocalStorage = () => {
    localStorage.removeItem("questionIndex");
    localStorage.removeItem("nextQuestion");
    localStorage.removeItem("comment");
    localStorage.removeItem("idState");
    localStorage.removeItem("authToken");
    localStorage.removeItem("fieldType");
    localStorage.removeItem("selectKnowledge");
    localStorage.removeItem("selectArea");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("isConversationStarted");
    localStorage.removeItem("chatMessages");
    localStorage.removeItem("loading");
  };

  const handleUserAnswer = async (text) => {
    setLoading(true);
    if (!nextQuestion) {
      userMessage("Concluir cadastro!");
    } else if (fieldType === "grade") {
      userMessage("Respostas enviadas!");
    } else if (fieldType === "selectKnowledge") {
      userMessage("Áreas de conhecimento enviadas!");
    } else if (fieldType === "select") {
      await userMessage(
        brazilStates.find((item) => Number(text) === Number(item.id))?.nome
      );
    } else {
      if (fieldType === "file") {
        if (text === "Não") {
          await userMessage("Deixar para depois");
        } else {
          await userMessage("Imagem enviada!");
        }
      } else {
        await userMessage(text);
      }
    }
    try {
      if (!nextQuestion) {
        const { data } = await handleAnswer(questionIndex, userEmail);

        if (data?.attributes) {
          // signUp(data);
          resetLocalStorage();
          navigate("/", { state: { scree: "screen2" } });
        }
      } else {
        if (fieldType === "grade") {
          const { data } = await handleAnswerAreas(
            questionIndex,
            selectArea,
            authToken
          );
          if (data?.attributes) {
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            localStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            setSelectedArea([]);
            localStorage.setItem("selectArea", JSON.stringify([]));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else if (fieldType === "selectKnowledge") {
          const { data } = await handleAnswerKnowledge(
            questionIndex,
            selectKnowledge,
            authToken
          );
          if (data?.attributes) {
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            localStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else if (fieldType === "file" && text !== "Não") {
          const { data } = await handleAnswerFile(
            questionIndex,
            text,
            authToken
          );
          if (data?.attributes) {
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            localStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else if (fieldType === "select") {
          const repon = brazilStates.find(
            (it) => Number(it.id) === Number(text)
          ).uf;
          const { data } = await handleAnswer(questionIndex, repon, authToken);
          if (data?.attributes) {
            if (questionIndex === 1) {
              setUserEmail(text);
            }
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            localStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else {
          const { data } = await handleAnswer(questionIndex, text, authToken);
          if (data?.attributes) {
            if (questionIndex === 1) {
              setUserEmail(text);
            }
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            localStorage.setItem(
              "nextQuestion",
              JSON.stringify(data.attributes?.nextQuestion)
            );
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const messagesEndRef = useRef(null);

  // console.log(nextQuestion);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    getBase64(file).then((result) => {
      file["base64"] = result;
      form.setFieldValue(`field`, result);
    });
  };

  const MessageCard = ({ message, sender }) => {
    const isDateMessage = (msg) => {
      const date = moment(msg, "YYYY-MM-DD", true); // Verifica se a data está no formato "YYYY/MM/DD"
      return date.isValid();
    };

    return (
      <div className={sender === "talent" ? "talentCard" : "systemCard"}>
        {isDateMessage(message)
          ? moment(message, "YYYY/MM/DD").format("DD/MM/YYYY")
          : message}
      </div>
    );
  };

  const handleToggleKnowledge = (item) => {
    setSelectedKnowledge((currentSelectedKnowledge) => {
      const isSelected = currentSelectedKnowledge.includes(item.id);
      if (isSelected) {
        return currentSelectedKnowledge.filter((id) => id !== item.id);
      } else {
        return [...currentSelectedKnowledge, item.id];
      }
    });
    localStorage.setItem(
      "selectKnowledge",
      JSON.stringify((currentSelectedKnowledge) => {
        const isSelected = currentSelectedKnowledge.includes(item.id);
        if (isSelected) {
          return currentSelectedKnowledge.filter((id) => id !== item.id);
        } else {
          return [...currentSelectedKnowledge, item.id];
        }
      })
    );
    form.setFieldValue("field", "selectKnowledge");
  };

  const handleValue = (value) => {
    const knowledgeAreas = {
      dont_know: 0,
      beginner: 1,
      can_manage: 2,
      can_teach: 3,
      expert: 4,
    };

    return knowledgeAreas[value] || false;
  };

  const handleAreaLevel = (item, sliderValue) => {
    setSelectedArea((currentSelectedArea) => {
      const updatedArea = currentSelectedArea.reduce((acc, currentItem) => {
        if (currentItem.id === item.id) {
          acc.push({ ...currentItem, level: sliderValue });
        } else {
          acc.push(currentItem);
        }
        return acc;
      }, []);
      console.log("Updated Area:", updatedArea);
      return updatedArea;
    });
    localStorage.setItem(
      "selectArea",
      JSON.stringify((currentSelectedArea) => {
        const updatedArea = currentSelectedArea.reduce((acc, currentItem) => {
          if (currentItem.id === item.id) {
            acc.push({ ...currentItem, level: sliderValue });
          } else {
            acc.push(currentItem);
          }
          return acc;
        }, []);
        console.log("Updated Area:", updatedArea);
        return updatedArea;
      })
    );

    form.setFieldValue("field", "Updated");
  };

  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    localStorage.setItem("comment", comment);
  }, [comment]);

  useEffect(() => {
    localStorage.setItem("authToken", authToken);
  }, [authToken]);

  useEffect(() => {
    localStorage.setItem("loading", loading);
  }, [loading]);

  useEffect(() => {
    localStorage.setItem("fieldType", fieldType);
  }, [fieldType]);

  useEffect(() => {
    localStorage.setItem("userEmail", userEmail);
  }, [userEmail]);

  const checkInputs = () => {
    // Verifica se há uma mensagem de erro e exibe o toast se necessário
    if (form.errors.field) {
      toast.error(form.errors.field);
    }
  };

  const [municipios, setMunicipios] = useState([]);

  const getMUnicipios = async (id) => {
    try {
      const data = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`
      );
      if (data) {
        setMunicipios(data?.data);
        localStorage.setItem("idState", id);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (idState) {
      getMUnicipios(idState);
    }
  }, [idState]);

  // console.log(municipios);

  return (
    <div className="containerBoxChatWeb">
      <div className="backgroundCHat"></div>
      <div className="containerChatSignUp">
        <div className="containerHeaderChatSignUp">
          <img src={logo} alt={""} />
        </div>
        <div
          style={{
            width: "90vw",
            height: "1px",
            backgroundColor: "#F6F2F3",
            alignSelf: "center",
          }}
        />
        <div className="containerBoxMessagens">
          {messages?.length > 0 &&
            messages.map((item, index) => (
              <MessageCard
                key={index}
                message={item.message}
                sender={item.sender}
              />
            ))}
          {/* 
        {form.errors.field && form.touched.field && form?.values?.field?.length <= 0 && (
          <div
            className="systemCard"
            style={{
              backgroundColor: "#EC5569",
              color: "#ffffff",
              fontWeight: "700",
            }}
          >
            {form.errors.field}
          </div>
        )} */}

          <div style={{ padding: "2x" }}>
            {messages.length > 0 && loadingMessage && (
              <div className="containerCardSystems">
                <div className="typing">{""}</div>
              </div>
            )}
          </div>
          {fieldType === "grade" && (
            <div className="cardSlideSend2">
              <div ref={messagesEndRef} />
            </div>
          )}

          {!loading && fieldType === "grade" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                justifyContent: "space-around",
                marginTop: "25px",
                marginBottom: "25px",
                width: "100%",
              }}
            >
              {nextQuestion?.options.map((item, index) => (
                <div className="cardSlideSend" key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "55px",
                      height: "45px",
                      borderRadius: "50%",
                      border: "1px solid black",
                    }}
                  >
                    {/* <Avatar /> */}
                    <img src={item?.logoUrl} alt="" className="iconTecnology" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div className="textCardSend">{item.optionText}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <NewSlider2
                        key={index}
                        item={{
                          ...item,
                          level: handleValue(item?.level) || 0,
                        }}
                        handleAreaLevel={handleAreaLevel}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {!loading && fieldType === "selectKnowledge" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "7px",
                justifyContent: "center",
              }}
            >
              {nextQuestion?.options.map((item, index) => (
                <button
                  className="cardAreas"
                  onClick={() => handleToggleKnowledge(item)}
                  style={{
                    backgroundColor:
                      selectKnowledge.includes(item.id) && "#2D2D2D",
                    border: selectKnowledge.includes(item.id) && "none",
                    color: selectKnowledge.includes(item.id) && "#ffffff",
                    width:
                      nextQuestion?.options.length % 2 !== 0 &&
                      index === nextQuestion.options.length - 1 &&
                      "97%", // Último item ocupa 100% se o número de itens for ímpar
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "20%",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={
                        selectKnowledge.includes(item.id)
                          ? selectedIcon
                          : selectIcon
                      }
                      style={{ width: "20px", height: "20px" }}
                      alt="edit icon"
                    />
                  </div>
                  <div style={{ display: "flex", width: "80%" }}>
                    {removeAfterColon(item.optionText)}
                  </div>
                </button>
              ))}
            </div>
          )}

          {fieldType !== "grade" && <div ref={messagesEndRef} />}
        </div>
        <div
          style={{
            width: "90vw",
            height: "1px",
            backgroundColor: "#F6F2F3",
            alignSelf: "center",
          }}
        />
        <div className="containerInputsChat">
          <form
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent:
                fieldType === "selectKnowledge" || fieldType === "grade"
                  ? "flex-end"
                  : "space-between",
              gap: "17px",
            }}
            action="javascript:void(0);"
            onSubmit={(e) => {
              e.preventDefault();
              form.submitForm();
            }}
          >
            {fieldType === "text" && (
              <input
                inputRef={inputRef}
                placeholder={"Digite aqui sua resposta"}
                autoCapitalize={"none"}
                value={form.values.field || ""}
                onChange={(e) => {
                  form.setFieldValue("field", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                disabled={loading}
                className="inputSTyleSend"
              />
            )}

            {fieldType === "date" && (
              <input
                className="inputSTyleSend ddatWidht"
                type="date"
                placeholder="Data de nascimento"
                label="Data de nascimento"
                autoCapitalize={"none"}
                value={form.values.field || calculateEighteenYearsOldDate()}
                onChange={(e) => {
                  form.setFieldValue("field", e.target.value);
                }}
                onClick={(e) => {
                  form.setFieldValue("field", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                disabled={loading}
              />
            )}

            {fieldType === "selectCity" && (
              <div class="selectContainer">
                <select
                  className="inputSTyleSendSelect"
                  placeholder="Insira aqui sua resposta"
                  value={form.values.field || 12}
                  onChange={(e) => {
                    form.setFieldValue("field", e.target.value);
                  }}
                  onBlur={() => form.setFieldTouched("field", true)}
                  disabled={loading}
                >
                  {municipios?.map((item, index) => (
                    <option
                      key={index}
                      value={item.nome}
                      className="inputListSelect"
                    >
                      {item.nome}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {fieldType === "select" && (
              <div class="selectContainer">
                <select
                  className="inputSTyleSendSelect"
                  placeholder="Insira aqui sua resposta"
                  value={form.values.field || 12}
                  onChange={(e) => {
                    form.setFieldValue("field", e.target.value || 12);
                    getMUnicipios(e.target.value);
                  }}
                  onBlur={() => form.setFieldTouched("field", true)}
                  disabled={loading}
                >
                  {brazilStates
                    .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os estados pelo nome
                    .map((item, index) => (
                      <option
                        key={index}
                        value={item.id}
                        className="inputListSelect"
                      >
                        {item.nome}
                      </option>
                    ))}
                </select>
              </div>
            )}

            {fieldType === "masked" && (
              <ReactInputMask
                mask={"+55 (99) 9 9999-9999"}
                type="number"
                value={form.values.field}
                onChange={(e) => form.setFieldValue(`field`, e.target.value)}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                maskChar={""}
                disabled={loading}
              >
                {() => (
                  <input
                    inputRef={inputRef}
                    className="inputSTyleSend"
                    label={"Digite aqui sua resposta"}
                    placeholder="Telefone"
                    autoCapitalize={"none"}
                    value={form.values.field}
                    disabled={loading}
                    inputMode="numeric"
                    kebo
                  />
                )}
              </ReactInputMask>
            )}

            {fieldType === "file" && (
              <button
                className="buttoninsert"
                style={{
                  backgroundColor: loading && "#F7F7F7",
                  border: loading && "1px solid #E3E3E3",
                  color: loading && "#E3E3E3",
                }}
              >
                <label className="" htmlFor="fupload">
                  <input
                    hidden
                    type="file"
                    id="fupload"
                    name="fupload"
                    accept=".jpeg, .png, .jpg"
                    onChange={handleFileInputChange}
                  />
                  Inserir foto de perfil
                </label>
              </button>
            )}

            {!nextQuestion ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => {
                    form.setFieldValue("field", "Concluir");
                    form.submitForm();
                  }}
                  disabled={loading}
                  className="buttoninsert2"
                  style={{
                    backgroundColor: loading && "#F7F7F7",
                    border: loading && "1px solid #E3E3E3",
                    color: loading && "#E3E3E3",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Terminar
                </button>
              </div>
            ) : fieldType === "file" ? (
              <button
                onClick={() => form.setFieldValue("field", "Não")}
                disabled={loading}
                className="buttoninsert2"
                style={{
                  backgroundColor: loading && "#F7F7F7",
                  border: loading && "1px solid #E3E3E3",
                  color: loading && "#E3E3E3",
                }}
              >
                Deixar para depois
              </button>
            ) : fieldType === "selectKnowledge" || fieldType === "grade" ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={async () => {
                    await form.submitForm();
                    checkInputs();
                  }}
                  disabled={loading}
                  className="buttoninsert2"
                  style={{
                    backgroundColor: loading && "#F7F7F7",
                    border: loading && "1px solid #E3E3E3",
                    color: loading && "#E3E3E3",
                    fontSize: "16px",
                    fontWeight: "500",
                    opacity:
                      loading ||
                      form?.values?.field === null ||
                      form?.values?.field === ""
                        ? "20%"
                        : "100%",
                  }}
                >
                  Próximo
                </button>
              </div>
            ) : (
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  style={{ padding: 0 }}
                  onClick={async () => {
                    await form.submitForm();
                    checkInputs();
                  }}
                  disabled={
                    loading ||
                    form?.values?.field === null ||
                    form?.values?.field === ""
                  }
                >
                  <img
                    src={sendIcon}
                    alt={""}
                    style={{
                      width: "50px",
                      height: "50px",
                      opacity:
                        loading ||
                        form?.values?.field === null ||
                        form?.values?.field === ""
                          ? "20%"
                          : "100%",
                    }}
                  />
                </IconButton>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
