import React, { useContext, useEffect, useState } from "react";
import { AuthorizedLayoutCompanies } from "../../components/AuthorizedLayoutCompanies";
import { AuthContext } from "../../providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";

import {
  Avatar,
  IconButton,
  Button,
  TextField,
  Badge,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import SearchIcon from "@mui/icons-material/Search";

import iconSearch from "../../assets/Frame 20 (3).png";
import iconCam from "../../assets/Frame 20 (2).png";
import iconEart from "../../assets/Frame 427320203.png";
import cicleFog from "../../assets/Frame 427320212.png";
import "./Painels.css";
import api from "../../utils/api";

const talents = [
  {
    id: 1,
    name: "Nome do talento",
    city: "Cidade do talento",
    skills: ["Gestão de pessoas", "Equipe", "Mailchimp", "Gmail"],
  },
  {
    id: 2,
    name: "Nome do talento",
    city: "Cidade do talento",
    skills: ["Gestão de pessoas", "Equipe", "Mailchimp", "Gmail"],
  },
  {
    id: 3,
    name: "Nome do talento",
    city: "Cidade do talento",
    skills: ["Gestão de pessoas", "Equipe", "Mailchimp", "Gmail"],
  },
];

const Painels = () => {
  const navigate = useNavigate();
  const { user, signOut } = useContext(AuthContext);
  const [listFavorites, setListFavorite] = useState([]);
  const [loading, setLoading] = useState([]);

  const getFavorite = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/companies/favorites`);

      if (data.data) {
        setListFavorite(data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFavorite();
  }, []);

  console.log(listFavorites);
  

  const TalentCard = ({ talent }) => {
    return (
      <div className="talent-card">
        <div className="header">
          <div className="img">t</div>
          <div>
            <div className="talent-name">{talent.name}</div>
            <div className="textSecondary">{talent.city}</div>
          </div>
        </div>

        <div className="selfie-container">
          <img src={iconCam} alt="small" />
          <div className="text">Selfie letrer</div>
        </div>

        <div className="skills-container">
          {talent.skills.map((skill, index) => (
            <div key={index} className="skill-button">
              {skill}
            </div>
          ))}
        </div>

        <div className="containerFvaoriteBtton">
          <img src={iconEart} alt="  " />
          <button>Perfil do talento</button>
        </div>
      </div>
    );
  };

  return (
    <AuthorizedLayoutCompanies>
      <div className="containerPainelHOme">
        <div className="headerPainel">
          <div>
            <div className="title">{`Olá ${user.name}!`}</div>
            <div className="subtitle">Seja bem-vindo(a) de volta</div>
          </div>
          <div className="btton" onClick={signOut}>SAIR</div>
          <div className="btton">Buscar talento</div>
        </div>

        <div className="contentContainer">
          <div className="conainerLeft">
            <img src={cicleFog} alt="" />
            <div className="title">{user?.companyName}</div>
            <div className="subtitle">Plano Básico</div>
          </div>

          <div className="conainerRight">
            <div className="talent-list-container">
              <div className="headerTitle">
                <div className="title">Lista de talentos favoritos</div>
                <div className="search-container">
                  <img src={iconSearch} alt="sarch" />

                  <input
                    placeholder="Pesquisar"
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>

              <div className="talent-list">
                {talents.map((talent) => (
                  <TalentCard key={talent.id} talent={talent} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayoutCompanies>
  );
};

export default Painels;
