import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import iconAddPhoto from "../../assets/Frame 18 (1).png";
import iconHeart from "../../assets/Vector (23).png";

import { AuthContext } from "../../providers/AuthProvider";
import astrounautStandingWave from "../../assets/posesPNG/astronout-standing-wave.png";
import editIcon from "../../assets/Group 1438.png";
import unmask from "../../utils/unmask";
import api from "../../utils/api";

import "./ClientProfile.css";
import ReactInputMask from "react-input-mask";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";

const ClientProfile = () => {
  const location = useLocation();
  const screenUrl = location.state?.scree;
  const navigate = useNavigate();
  const { updateUser, user, signOut } = useContext(AuthContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [tempPhone, setTempPhone] = useState("");
  const [scree, setScreen] = useState(screenUrl ? screenUrl : "screen1");

  const checkList = {
    isCulturalFitFilled: user?.isCulturalFitFilled,
    isSelfieLetterFilled: user?.isSelfieLetterFilled,
    isTalentKnowledgeAreaFilled: user?.isTalentKnowledgeAreaFilled,
    isTalentKnowledgeSkillFilled: user?.isTalentKnowledgeSkillFilled,
  };

  const form = useFormik({
    initialValues: {
      name: user?.name || "",
      birthday: user?.birthdate
        ? moment(user.birthdate).format("DD/MM/YYYY")
        : "",
      email: user?.email || "",
      phone: user?.phone || "",
      avatar: user?.avatar || "",
      linkedin: user?.linkedinUrl || "",
    },

    // validationSchema: yup.object({
    //   money: yup.number("Deve ser um número"),
    //   ratio: yup.number("Deve ser um número").required("Campo obrigatório"),
    // }),

    onSubmit: (values) => updateSettings(values),
  });

  const resetForm = (values) => {
    form.setValues({
      name: values?.name || "",
      birthday: values?.birthdate
        ? moment(values.birthdate).format("DD/MM/YYYY")
        : "",
      email: values?.email || "",
      phone: values?.phone || "",
      avatar: values?.avatar || "",
      linkedin: values?.linkedinUrl || "",
    });
  };
  
  // console.log(user);
  
const [avatarSrc, setAvatarSrc] = useState(user?.avatar || editIcon);

  const handleImageError = () => {
    setAvatarSrc(user?.avatar);
  };

 useEffect(() => {
    if (user.avatar) {
      handleImageError();
    }
  }, [user]);

  // console.log(user);
  
  const updateSettings = async (values) => {
    setSaveLoading(true);

    if (values?.avatar !== user?.avatar) {
      try {
        const { data } = await api.put(`/talents/talents/${user.id}`, {
          data: {
            attributes: {
              avatar: { data: values?.avatar },
              name: values.name.trim(),
              phone: unmask(values.phone.trim()),
              birthdate: moment(values.birthday, "DD/MM/YYYY").toISOString(),
              email: values.email,
              linkedin_url: values.linkedin,
            },
          },
        });
        if (data) {
          updateUser(data);
          
          resetForm(data.data.attributes);
          toast.success("Dados da conta atualizados com sucesso!");
        }
      } catch (error) {
        //console.log(error);
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
      } finally {
        setSaveLoading(false);
      }
    } else {
      try {
        const { data } = await api.put(`/talents/talents/${user.id}`, {
          data: {
            attributes: {
              name: values.name.trim(),
              phone: unmask(values.phone.trim()),
              birthdate: moment(values.birthday, "DD/MM/YYYY").toISOString(),
              email: values.email,
              linkedin_url: values.linkedin,
            },
          },
        });
        if (data) {
          updateUser(data);
          resetForm(data.data.attributes);
          toast.success("Dados da conta atualizados com sucesso!");
        }
      } catch (error) {
        //console.log(error);
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
      } finally {
        setSaveLoading(false);
      }
    }
  };

  useEffect(() => {
    setTempPhone(form.values.phone);
  }, [form.values.phone]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        // console.log("File Is", file);
        form.setFieldValue(`avatar`, result);
        setAvatarSrc(result);
        const updateSettings = async () => {
          try {
            const { data } = await api.put(`/talents/talents/${user.id}`, {
              data: {
                attributes: {
                  avatar: { data: result },
                },
              },
            });
            if (data) {
              updateUser(data);
              resetForm(data.data.attributes);
              toast.success("Dados da conta atualizados com sucesso!");
            }
          } catch (error) {
            //console.log(error);
            toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
          } finally {
            setSaveLoading(false);
          }
        };
        updateSettings();
      })
      .catch((error) => {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(`${error.response.data.errors[0].title}`);
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      }
    );
  };

  const contarTrues = (obj) => {
    let contador = 0;

    for (let key in obj) {
      if (obj[key] === true) {
        contador++;
      }
    }

    return contador;
  };

  return (
    <AuthorizedLayout>
      <div className="containerProfileTalents" style={{backgroundColor: '#2D2D2D'}}>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#2D2D2D",
            }}
          >
            {contarTrues(checkList) <= 3 && (
              <div className="containerHeaderProfile">
                <div className="cardCompleteProfile">
                  <div className="cardLeft">
                    <div className="textProfile">Complete seu perfil</div>
                    <LinearProgress
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        [`&.${linearProgressClasses.colorPrimary}`]: {
                          backgroundColor: "#BEFCF9",
                          border: "2px solid #BEFCF9",
                        },
                        [`& .${linearProgressClasses.bar}`]: {
                          borderRadius: "5px 0px 0px 5px",
                          backgroundColor: "#57BAB8",
                        },
                      }}
                      value={((contarTrues(checkList) / 4) * 100).toFixed(2)}
                      variant="determinate"
                    />

                    <div className="textProfileSUb">
                      Perfis completos e de qualidade aumentam em 4,5 vezes suas
                      chances de ser contratado por clientes.
                    </div>

                    <div
                      className="button"
                      onClick={() => navigate("/settings/status")}
                    >
                      Clique aqui
                    </div>
                  </div>

                  <div className="cardRigth">
                    <img
                      src={astrounautStandingWave}
                      alt="backgroud img"
                      style={{
                        position: "absolute",
                        width: "131px",
                        top: "-20px",
                        right: "0px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="containerContentProfile" style={{flex : 1}}>
              <div className="containerPhotoProfile">
                <label className="uploadIcon" htmlFor="fupload">
                  <div style={{ position: "relative" }}>
                    {user.avatar && (
                      <img
                        src={iconAddPhoto}
                        alt="edit"
                        style={{
                          position: "absolute",
                          bottom: "-10px",
                          right: "5px",
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    )}
                    <img
                      src={avatarSrc}
                      alt="edit"
                      className={form.values.avatar ? "fotoIcon" : "editIcon"}
                      htmlFor="fupload"
                      onError={handleImageError}
                    />
                  </div>

                  <input
                    type="file"
                    id="fupload"
                    name="fupload"
                    className="fupload form-control"
                    accept=".jpeg, .png, .jpg"
                    onChange={handleFileInputChange}
                    style={{ display: "none" }}
                  />
                </label>
              </div>

              <div
                className="containerInput"
                style={{ marginBottom: "20px", lineHeight: "5px" }}
              >
                <div className="text">Nome Completo</div>
                <input
                  type="text"
                  label="Nome completo"
                  placeholder="Nome completo"
                  className="inputLogin"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.name}
                  name="name"
                />
                {form.touched.name && form.errors.name && (
                  <div style={{ color: "red" }} className="text">
                    {form.errors.name}
                  </div>
                )}
              </div>

              <div
                className="containerInput"
                style={{ marginBottom: "20px", lineHeight: "5px" }}
              >
                <div className="text">Data de nascimento</div>

                <ReactInputMask
                  mask="99/99/9999"
                  placeholder="Data de nascimento"
                  value={form.values.birthday}
                  onChange={(e) =>
                    form.setFieldValue(`birthday`, e.target.value)
                  }
                  onBlur={() => {
                    form.setFieldTouched(`birthday`, true);
                  }}
                  className="inputLogin"
                />
                {form.touched.birthday && form.errors.birthday && (
                  <div style={{ color: "red" }} className="text">
                    {form.errors.birthday}
                  </div>
                )}
              </div>

              <div
                className="containerInput"
                style={{ marginBottom: "20px", lineHeight: "5px" }}
              >
                <div className="text">Email</div>
                <input
                  type="email"
                  label="E-mail"
                  placeholder="E-mail"
                  className="inputLogin"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.email}
                  name="email"
                />
                {form.touched.email && form.errors.email && (
                  <div style={{ color: "red" }} className="text">
                    {form.errors.email}
                  </div>
                )}
              </div>

              <div
                className="containerInput"
                style={{ marginBottom: "20px", lineHeight: "5px" }}
              >
                <div className="text">Telefone</div>
                <ReactInputMask
                  mask={
                    tempPhone.replace(/\s/g, "").length > 13
                      ? "+55 (99) 9 9999-9999"
                      : "+55 (99) 9999-99999"
                  }
                  placeholder="Telefone"
                  value={form.values.phone}
                  onChange={(e) => form.setFieldValue(`phone`, e.target.value)}
                  onBlur={() => {
                    form.setFieldTouched(`phone`, true);
                  }}
                  className="inputLogin"
                />
                {form.touched.phone && form.errors.phone && (
                  <div style={{ color: "red" }} className="text">
                    {form.errors.phone}
                  </div>
                )}
              </div>

              <div
                className="containerInput"
                style={{ marginBottom: "20px", lineHeight: "5px" }}
              >
                <div className="text">Linkedin</div>
                <input
                  type="text"
                  label="Linkedin"
                  placeholder="Linkedin"
                  className="inputLogin"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.linkedin}
                  name="linkedin"
                />
                {form.touched.linkedin && form.errors.linkedin && (
                  <div style={{ color: "red" }} className="text">
                    {form.errors.linkedin}
                  </div>
                )}
              </div>

                <div
                  className={`buttonClientProfile ${saveLoading && "loading"}`}
                  onClick={form.submitForm}
                  style={{ width: "75vw", marginTop: "0px" }}
                >
                  {saveLoading ? "Salvando" : "Salvar"}
                </div>

                <div onClick={() => signOut()} className="buttonSiginOut">
                  Sair
                </div>
 
            </div>
          </div>
      <div className="footerPageMObileProfile">
            Desenvolvido com
            <img src={iconHeart} alt="" />
            <strong>© eComLovers®</strong>
          </div>
      </div>
    </AuthorizedLayout>
  );
};

export default ClientProfile;
