import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, MenuItem, Slider, TextField, Typography } from "@mui/material";
import LayoutSpace from "../components/LayoutSpace";
import logo from "../../assets/ecomlovers-logo-transparente.svg";
import sendMessage from "../../assets/sendMessage.svg";
import systemIcon from "../../assets/systemIcon.svg";
import talentIcon from "../../assets/talentIcon.svg";
import useIsMobileDevice from "../../utils/mobileCheck";
import { brazilStates } from "../../utils/states";
import ReactInputMask from "react-input-mask";
import Checkout from "./components/Checkout";
import "./SignUp.css"; // Estilos externos
import api from "../../utils/api";
import { toast } from "react-toastify";
import StripeWrapper from "../../utils/StripeWrapper";
import { AuthContext } from "../../providers/AuthProvider";

const ChatCadastroUsuario = () => {
  const isMobile = useIsMobileDevice();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [responses, setResponses] = useState({});
  const [response, setResponse] = useState("");
  const [nextQuestion, setNextQuestion] = useState("");
  const [segundNextQuestion, setSegundNextQuestion] = useState(null);
  const [isComplete, setIsComplete] = useState(false); // Estado para saber se o cadastro foi finalizado
  const [name, setName] = useState("");
  const [tempPhone, setTempPhone] = useState("");
  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "Boas vindas à eComLovers! Qual o seu nome?",
      sender: "bot",
      type: "text",
    },
  ]);
  const { signUpCompany } = useContext(AuthContext);
  const valueContext = useContext(AuthContext);
  const [authToken, setAuthToken] = useState("");

  const handleInputChange = (event) => {
    event.preventDefault(); // Prevê o comportamento padrão do formulário

    // Atualiza as respostas com o novo valor
    setResponses((prev) => ({ ...prev, [name]: response }));

    // Adiciona a mensagem do usuário ao histórico
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: response, sender: "user" }, // Use 'value' aqui
    ]);

    setResponse("");
    setName("");
    setTimeout(() => {
      // Adiciona a resposta do bot ao histórico
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: nextQuestion,
          sender: "bot",
        },
      ]);
      setStep(step + 1);
    }, 1000);

    if (segundNextQuestion) {
      setTimeout(() => {
        // Adiciona a resposta do bot ao histórico
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: segundNextQuestion,
            sender: "bot",
          },
        ]);
        setStep(step + 1);
      }, 1500);
    }

    if (name === "contato") {
      setTimeout(() => {
        setStep(step + 1);
        handleLogin();
      }, 2500);
    }

    setNextQuestion("");
    setSegundNextQuestion(null);
  };

  const MessageCard = ({ message, sender }) => {
    return (
      <>
        {message && (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              height: "auto",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            {sender === "bot" && (
              <img
                src={systemIcon}
                alt={""}
                style={{
                  width: isMobile ? "75px" : "100px",
                  height: isMobile ? "75px" : "100px",
                  marginRight: "10px",
                }}
              />
            )}
            <Typography
              fontWeight={500}
              sx={{
                marginBottom: "15px",
                border:
                  sender === "user" ? "1px solid #BEFCF9" : "1px solid #FFE4E5",
                backgroundColor: sender === "user" ? "#BEFCF9" : "#FFE4E5",
                color: "black",
                borderRadius: "20px",
                padding: "25px",
                display: "flex",
                justifyContent: "flex-start",
                alignSelf: sender === "user" ? "self-end" : "self-start",
                width: "100%",
              }}
            >
              {message}
            </Typography>
            {sender === "user" && (
              <img
                src={talentIcon}
                alt={""}
                style={{
                  width: isMobile ? "75px" : "100px",
                  height: isMobile ? "75px" : "100px",
                  marginLeft: "10px",
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const marks = [
    { value: 10000, label: "10K" },
    { value: 200000, label: "200K" },
    { value: 500000, label: "500K" },
    { value: 700000, label: "700K" },
    { value: 1000000, label: "1M" },
    { value: 2000000, label: "2M" },
  ];

  function valuetext(value) {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(0)}K`;
    }
    return value.toString();
  }

  const renderStep = () => {
    if (isComplete) {
      return <></>;
    }

    switch (step) {
      case 0:
        return (
          <>
            <input
              type="text"
              className="styleInput"
              name="nome"
              value={response}
              onChange={(e) => {
                setResponse(e.target.value);
                setNextQuestion("E qual é o nome da empresa?");
                setName("nome");
              }}
              placeholder="Digite seu nome"
            />
            <button type="submit">
              <img
                src={sendMessage}
                alt={""}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </button>
          </>
        );
      case 1:
        return (
          <>
            <input
              className="styleInput"
              type="text"
              name="companie"
              value={response}
              onChange={(e) => {
                setResponse(e.target.value);
                setNextQuestion(
                  "Por gentileza, informe seu e-mail. Dê preferência ao e-mail institucional, se tiver."
                );
                setName("companie");
              }}
              placeholder="Digite seu nome"
            />
            <button type="submit">
              <img
                src={sendMessage}
                alt={""}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </button>
          </>
        );
      case 2:
        return (
          <>
            <input
              className="styleInput"
              type="email"
              name="email"
              value={response}
              onChange={(e) => {
                setResponse(e.target.value);
                setNextQuestion(
                  "Agora precisamos do número de telefone da sua empresa. Caso não exista, coloque o número de um representante 😉"
                );
                setName("email");
              }}
              placeholder="Digite seu nome"
            />
            <button type="submit">
              <img
                src={sendMessage}
                alt={""}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </button>
          </>
        );
      case 3:
        return (
          <>
            <ReactInputMask
              className="styleInput"
              mask={
                tempPhone.replace(/\s/g, "").length > 14
                  ? "+99 99 99999-9999"
                  : "+99 99 9999-99999"
              }
              value={response}
              onChange={(e) => {
                setResponse(e.target.value);
                setNextQuestion(
                  "Qual é a sua função/cargo na nome da empresa?"
                );
                setName("phone");
              }}
              maskChar={""}
              // disabled={loading}
            >
              {() => (
                <TextField
                  // inputRef={inputRef}
                  label={"Digite aqui sua resposta"}
                  autoCapitalize={"none"}
                  value={response}
                  // onChange={(e) => setResponse(e.target.value)}
                  color="secondary"
                  variant="outlined"
                  style={{ width: "100%" }}
                  // disabled={loading}
                />
              )}
            </ReactInputMask>
            <button type="submit">
              <img
                src={sendMessage}
                alt={""}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </button>
          </>
        );

      case 4:
        return (
          <>
            <TextField
              label={"Insira aqui sua resposta"}
              autoCapitalize={"none"}
              value={response}
              onChange={(e) => {
                setResponse(e.target.value);
                setNextQuestion(
                  "Em qual Estado está localizado a sede da empresa?"
                );
                setName("role");
              }}
              color="secondary"
              variant="outlined"
              style={{ width: "100%" }}
              // disabled={loading}
              select
            >
              {[
                "Pessoas",
                "Administrativo",
                "Financeiro",
                "Diretoria",
                "Tecnologia",
                "Operação",
                "Marketing",
                "Comercial",
                "Jurídico",
              ].map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
            <button type="submit">
              <img
                src={sendMessage}
                alt={""}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </button>
          </>
        );
      case 5:
        return (
          <>
            <TextField
              label={"Insira aqui sua resposta"}
              autoCapitalize={"none"}
              value={response}
              onChange={(e) => {
                setResponse(e.target.value);
                setNextQuestion(
                  "Certo! Em qual cidade de Estado selecionado anteriormente? "
                );
                setName("state");
              }}
              color="secondary"
              variant="outlined"
              style={{ width: "100%" }}
              // disabled={loading}
              select
            >
              {brazilStates
                .map((item) => item.nome)
                .map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </TextField>
            <button type="submit">
              <img
                src={sendMessage}
                alt={""}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </button>
          </>
        );

      case 6:
        return (
          <>
            <input
              type="text"
              name="city"
              className="styleInput"
              value={response}
              onChange={(e) => {
                setResponse(e.target.value);
                setNextQuestion("Seu Ecommerce está ativo?");
                setName("city");
              }}
              placeholder="Digite a cidade"
            />
            <button type="submit">
              <img
                src={sendMessage}
                alt={""}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </button>
          </>
        );
      case 7:
        return (
          <div className="containerButonsRegister">
            <button
              className="button1"
              onClick={() => {
                setResponse("Sim");
                setNextQuestion("Qual é o tamanho da sua equipe?");
                setName("checkEcommerce");
              }}
            >
              Sim
            </button>
            <button
              className="button2"
              onClick={() => {
                setResponse("Não");
                setNextQuestion("Pretende abrir o seu ecommerce quando?");
                setName("checkEcommerce");
              }}
            >
              Não
            </button>
          </div>
        );
      case 8:
        if (responses.checkEcommerce === "Sim") {
          return (
            <>
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion(
                    "Qual é a estimativa de faturamento da empresa nos próximos 12 meses?"
                  );
                  setName("totalEquipe");
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "100%" }}
                // disabled={loading}
                select
              >
                {["1-3", "4-8", "9-15", "+15"].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </>
          );
        } else {
          return (
            <>
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion("Qual será o tamanho da sua equipe?");
                  setName("dateStart");
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "100%" }}
                // disabled={loading}
                select
              >
                {["No próximo mês", "3 meses", "6 meses", "12 meses"].map(
                  (item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  )
                )}
              </TextField>
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </>
          );
        }

      case 9:
        if (responses.checkEcommerce === "Não") {
          return (
            <>
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion(
                    "Qual é a estimativa de faturamento da empresa nos próximos 12 meses?"
                  );
                  setName("totalEquipe");
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "100%" }}
                // disabled={loading}
                select
              >
                {["1-3", "4-8", "9-15", "+15"].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </>
          );
        } else {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "30px",
                width: "100%",
              }}
            >
              <Slider
                aria-label="Custom marks"
                defaultValue={20000}
                getAriaValueText={valuetext}
                step={10000} // Ajuste o passo para 10.000
                valueLabelDisplay="auto"
                marks={marks}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion("Você já tem um RH?");
                  setName("totalFaturamento");
                }}
                min={10000} // Defina um valor mínimo se necessário
                max={2000000} // Defina um valor máximo se necessário
              />
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </div>
          );
        }
      case 10:
        if (responses.checkEcommerce === "Não") {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "30px",
                width: "100%",
              }}
            >
              <Slider
                aria-label="Custom marks"
                defaultValue={20000}
                getAriaValueText={valuetext}
                step={10000} // Ajuste o passo para 10.000
                valueLabelDisplay="auto"
                marks={marks}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion("Você já tem um RH?");
                  setName("totalFaturamento");
                }}
                min={10000} // Defina um valor mínimo se necessário
                max={2000000} // Defina um valor máximo se necessário
              />
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </div>
          );
        } else {
          return (
            <div className="containerButonsRegister">
              <button
                className="button1"
                onClick={() => {
                  setResponse("Sim");
                  setName("checkRh");
                  setNextQuestion(
                    "Seu time já consegue selecionar e entrevistar pessoas com qualidade?"
                  );
                }}
              >
                Sim
              </button>
              <button
                className="button2"
                onClick={() => {
                  setResponse("Não");
                  setSegundNextQuestion(
                    "Estamos quase lá! Quando vocês procuram candidatos ou abrem vagas, utilizam qual plataforma?"
                  );
                  setNextQuestion(
                    "Mesmo sem um RH dedicado, com a EcomLovers você terá todas as ferramentas necessárias para encontrar os melhores talentos para a sua empresa. Nossa plataforma é intuitiva e fácil de usar, e nossa equipe de suporte está sempre pronta para te auxiliar. Conte conosco!"
                  );
                  setName("checkRh");
                }}
              >
                Não
              </button>
            </div>
          );
        }
      case 11:
        if (responses.checkEcommerce === "Não" && !responses.checkRh) {
          return (
            <div className="containerButonsRegister">
              <button
                className="button1"
                onClick={() => {
                  setResponse("Sim");
                  setName("checkRh");
                  setNextQuestion(
                    "Seu time já consegue selecionar e entrevistar pessoas com qualidade?"
                  );
                }}
              >
                Sim
              </button>
              <button
                className="button2"
                onClick={() => {
                  setResponse("Não");
                  setSegundNextQuestion(
                    "Estamos quase lá! Quando vocês procuram candidatos ou abrem vagas, utilizam qual plataforma?"
                  );
                  setNextQuestion(
                    "Mesmo sem um RH dedicado, com a EcomLovers você terá todas as ferramentas necessárias para encontrar os melhores talentos para a sua empresa. Nossa plataforma é intuitiva e fácil de usar, e nossa equipe de suporte está sempre pronta para te auxiliar. Conte conosco!"
                  );
                  setName("checkRh");
                }}
              >
                Não
              </button>
            </div>
          );
        } else if (responses.checkRh === "Sim") {
          return (
            <div className="containerButonsRegister">
              <button
                className="button1"
                onClick={() => {
                  setResponse("Sim");
                  setName("checkRhQuality");
                  setSegundNextQuestion(
                    "Estamos quase lá! Quando vocês procuram candidatos ou abrem vagas, utilizam qual plataforma?"
                  );
                  setNextQuestion(
                    "Que alegria ter sua equipe a bordo!  Agora, vocês fazem parte de uma rede de profissionais que acreditam em soluções inovadoras para o recrutamento. A EcomLovers foi desenvolvida para transformar a maneira como as empresas encontram seus talentos. Vamos juntos construir um futuro mais eficiente e colaborativo!"
                  );
                }}
              >
                Sim
              </button>
              <button
                className="button2"
                onClick={() => {
                  setResponse("Não");
                  setSegundNextQuestion(
                    "Estamos quase lá! Quando vocês procuram candidatos ou abrem vagas, utilizam qual plataforma?"
                  );
                  setNextQuestion(
                    "Mesmo sem um RH dedicado, com a EcomLovers você terá todas as ferramentas necessárias para encontrar os melhores talentos para a sua empresa. Nossa plataforma é intuitiva e fácil de usar, e nossa equipe de suporte está sempre pronta para te auxiliar. Conte conosco!"
                  );
                  setName("checkRh");
                }}
              >
                Não
              </button>
            </div>
          );
        } else {
          return (
            <>
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setSegundNextQuestion(
                    "E pra finalizar… Como você conheceu a eComLovers?"
                  );
                  setNextQuestion(
                    "E por último antes de mostrar o melhor plano para a sua empresa"
                  );
                  setName("plataformas");
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "100%" }}
                // disabled={loading}
                select
              >
                {[
                  "LinkedIn",
                  "Gupy",
                  "Vagas",
                  "Indeed",
                  "Trampos",
                  "Sólides",
                  "Recrutei",
                  "Outra",
                ].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </>
          );
        }
      case 12:
        if (responses.checkEcommerce === "Não" && responses.checkRh === "Sim") {
          return (
            <div className="containerButonsRegister">
              <button
                className="button1"
                onClick={() => {
                  setResponse("Sim");
                  setName("checkRhQuality");
                  setSegundNextQuestion(
                    "Estamos quase lá! Quando vocês procuram candidatos ou abrem vagas, utilizam qual plataforma?"
                  );
                  setNextQuestion(
                    "Que alegria ter sua equipe a bordo!  Agora, vocês fazem parte de uma rede de profissionais que acreditam em soluções inovadoras para o recrutamento. A EcomLovers foi desenvolvida para transformar a maneira como as empresas encontram seus talentos. Vamos juntos construir um futuro mais eficiente e colaborativo!"
                  );
                }}
              >
                Sim
              </button>
              <button
                className="button2"
                onClick={() => {
                  setResponse("Não");
                  setSegundNextQuestion(
                    "Estamos quase lá! Quando vocês procuram candidatos ou abrem vagas, utilizam qual plataforma?"
                  );
                  setNextQuestion(
                    "Mesmo sem um RH dedicado, com a EcomLovers você terá todas as ferramentas necessárias para encontrar os melhores talentos para a sua empresa. Nossa plataforma é intuitiva e fácil de usar, e nossa equipe de suporte está sempre pronta para te auxiliar. Conte conosco!"
                  );
                  setName("checkRhQuality");
                }}
              >
                Não
              </button>
            </div>
          );
        } else if (responses.checkRh && !responses.plataformas) {
          return (
            <>
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion(
                    "E por último antes de mostrar o melhor plano para a sua empresa"
                  );
                  setSegundNextQuestion(
                    "E pra finalizar… Como você conheceu a eComLovers?"
                  );
                  setName("plataformas");
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "100%" }}
                // disabled={loading}
                select
              >
                {[
                  "LinkedIn",
                  "Gupy",
                  "Vagas",
                  "Indeed",
                  "Trampos",
                  "Sólides",
                  "Recrutei",
                  "Outra",
                ].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </>
          );
        } else if (!responses.contato && responses.plataformas) {
          return (
            <>
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion(
                    "De acordo com o que você me relatou aqui, este plano é perfeito pra sua empresa! "
                  );
                  setName("contato");
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "100%" }}
                // disabled={loading}
                select
              >
                {[
                  "LinkedIn",
                  "Gupy",
                  "Vagas",
                  "Indeed",
                  "Trampos",
                  "Sólides",
                  "Recrutei",
                  "Outra",
                ].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </>
          );
        }
        break;
      case 13:
        if (
          responses.checkEcommerce === "Não" &&
          responses.checkRh &&
          !responses.plataformas
        ) {
          return (
            <>
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion(
                    "E por último antes de mostrar o melhor plano para a sua empresa"
                  );
                  setSegundNextQuestion(
                    "E pra finalizar… Como você conheceu a eComLovers?"
                  );
                  setName("plataformas");
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "100%" }}
                // disabled={loading}
                select
              >
                {[
                  "LinkedIn",
                  "Gupy",
                  "Vagas",
                  "Indeed",
                  "Trampos",
                  "Sólides",
                  "Recrutei",
                  "Outra",
                ].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </>
          );
        } else if (!responses.contato && responses.plataformas) {
          return (
            <>
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion(
                    "De acordo com o que você me relatou aqui, este plano é perfeito pra sua empresa! "
                  );
                  setName("contato");
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "100%" }}
                // disabled={loading}
                select
              >
                {[
                  "LinkedIn",
                  "Gupy",
                  "Vagas",
                  "Indeed",
                  "Trampos",
                  "Sólides",
                  "Recrutei",
                  "Outra",
                ].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </>
          );
        }
        break;
      case 14:
        if (!responses.contato) {
          return (
            <>
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={response}
                onChange={(e) => {
                  setResponse(e.target.value);
                  setNextQuestion(
                    "De acordo com o que você me relatou aqui, este plano é perfeito pra sua empresa! "
                  );
                  setName("contato");
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "100%" }}
                // disabled={loading}
                select
              >
                {[
                  "LinkedIn",
                  "Gupy",
                  "Vagas",
                  "Indeed",
                  "Trampos",
                  "Sólides",
                  "Recrutei",
                  "Outra",
                ].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <button type="submit">
                <img
                  src={sendMessage}
                  alt={""}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </>
          );
        }
        break;
      default:
        return <></>;
    }
  };

  const stylesImage = { height: "75vh" };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(`/companies/companies`, {
        data: {
          type: "companies",
          attributes: {
            name: responses.nome,
            phone: responses.phone,
            email: responses.email,
            password: "senha123",
            companyName: responses.companie,
            companyPhone: responses.phone,
            companyEmail: responses.email,
            role: responses.role,
            hasEcommerce: responses.checkEcommerce === "Sim" ? true : false,
            ecommerceTeamSize:
              responses.checkEcommerce === "Sim" ? [responses.totalEquipe] : [],
            plannedEcommerceTeamSize:
              responses.checkEcommerce === "Não" ? [responses.totalEquipe] : [],
            expectedRevenueRange: responses.totalFaturamento,
            hasHr: responses.checkRh === "Sim" ? true : false,
            hiringPlatforms: [responses.plataformas || ""],
            discoveryMethod: responses.contato || "",
            cnpj: "",
            addressAttributes: {
              state: responses.state,
              city: responses.city,
              zipCode: "0000-000",
              address: "Rua Um, 345",
            },
          },
        },
      });

      if (data?.data) {
        handleUser(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("erro ao cadastrar");
    } finally {
      setLoading(false);
    }
  };

  const handleUser = (data) => {
    signUpCompany(data);
    setAuthToken(data.data.attributes.authToken);
  };

  useEffect(() => {
    if (authToken) {
      setIsComplete(true);
    }
  }, [authToken]);

  return (
    <LayoutSpace stylesImage={stylesImage}>
      {isComplete ? (
        <Checkout authToken={authToken} />
      ) : (
        <div className="chat-container">
          <div className="chat-box">
            {messages.map((msg, index) => (
              <MessageCard key={index} message={msg.text} sender={msg.sender} />
            ))}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={handleInputChange} className="chat-input">
            {renderStep()}
          </form>
        </div>
      )}
    </LayoutSpace>
  );
};

export default ChatCadastroUsuario;
