//stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import api from "./api";

const stripePromise = loadStripe(
  "pk_test_51PvNO2AUy1FdVGgybnAzO5VZsNCVjBIG3qPFBTyWY7CMyNd9GJYCuUOBWwlhYnXs0Tn2EwTY7NYmzzpkMZXcSGTJ00m28247sm"
);

export default function StripeWrapper({ children, plan, authToken }) {
  const [clientSecret, setClientSecret] = useState("");
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");

  const appearance = {
    theme: "stripe",
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = "auto";

  const getIntent = async () => {
    try {
      const { data } = await api.post(
        "/companies/subscriptions/payment_intents",
        {
          data: {
            attributes: {
              paymentPlanId: plan,
            },
          },
        },
        authToken && {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (data?.clientSecret) {
        setClientSecret(data.clientSecret);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    getIntent();
  }, []);

  return (
    <>
      {clientSecret ? (
        <Elements
          options={{ clientSecret, appearance, loader }}
          stripe={stripePromise}
        >
          {children}
        </Elements>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}
