export const brazilStates = [
  {
    id: 12,
    uf: "AC",
    nome: "Acre",
    regiao: {
      id: 1,
      uf: "N",
      nome: "Norte"
    }
  },
  {
    id: 27,
    uf: "AL",
    nome: "Alagoas",
    regiao: {
      id: 2,
      uf: "NE",
      nome: "Nordeste"
    }
  },
  {
    id: 16,
    uf: "AP",
    nome: "Amapá",
    regiao: {
      id: 1,
      uf: "N",
      nome: "Norte"
    }
  },
  {
    id: 23,
    uf: "CE",
    nome: "Ceará",
    regiao: {
      id: 2,
      uf: "NE",
      nome: "Nordeste"
    }
  },
  {
    id: 53,
    uf: "DF",
    nome: "Distrito Federal",
    regiao: {
      id: 5,
      uf: "CO",
      nome: "Centro-Oeste"
    }
  },
  {
    id: 29,
    uf: "BA",
    nome: "Bahia",
    regiao: {
      id: 2,
      uf: "NE",
      nome: "Nordeste"
    }
  },
  {
    id: 13,
    uf: "AM",
    nome: "Amazonas",
    regiao: {
      id: 1,
      uf: "N",
      nome: "Norte"
    }
  },
  {
    id: 31,
    uf: "MG",
    nome: "Minas Gerais",
    regiao: {
      id: 3,
      uf: "SE",
      nome: "Sudeste"
    }
  },
  {
    id: 33,
    uf: "RJ",
    nome: "Rio de Janeiro",
    regiao: {
      id: 3,
      uf: "SE",
      nome: "Sudeste"
    }
  },
  {
    id: 24,
    uf: "RN",
    nome: "Rio Grande do Norte",
    regiao: {
      id: 2,
      uf: "NE",
      nome: "Nordeste"
    }
  },
  {
    id: 43,
    uf: "RS",
    nome: "Rio Grande do Sul",
    regiao: {
      id: 4,
      uf: "S",
      nome: "Sul"
    }
  },
  {
    id: 15,
    uf: "PA",
    nome: "Pará",
    regiao: {
      id: 1,
      uf: "N",
      nome: "Norte"
    }
  },
  {
    id: 25,
    uf: "PB",
    nome: "Paraíba",
    regiao: {
      id: 2,
      uf: "NE",
      nome: "Nordeste"
    }
  },
  {
    id: 22,
    uf: "PI",
    nome: "Piauí",
    regiao: {
      id: 2,
      uf: "NE",
      nome: "Nordeste"
    }
  },
  {
    id: 26,
    uf: "PE",
    nome: "Pernambuco",
    regiao: {
      id: 2,
      uf: "NE",
      nome: "Nordeste"
    }
  },
  {
    id: "41",
    uf: "PR",
    nome: "Paraná",
    regiao: {
      id: '4',
      uf: "S",
      nome: "Sul"
    }
  },
  {
    id: 42,
    uf: "SC",
    nome: "Santa Catarina",
    regiao: {
      id: 4,
      uf: "S",
      nome: "Sul"
    }
  },
  {
    id: 35,
    uf: "SP",
    nome: "São Paulo",
    regiao: {
      id: 3,
      uf: "SE",
      nome: "Sudeste"
    }
  },
  {
    id: 34,
    uf: "SE",
    nome: "Sergipe",
    regiao: {
      id: 2,
      uf: "NE",
      nome: "Nordeste"
    }
  },
  {
    id: 11,
    uf: "RO",
    nome: "Rondônia",
    regiao: {
      id: 1,
      uf: "N",
      nome: "Norte"
    }
  },
  {
    id: 14,
    uf: "RR",
    nome: "Roraima",
    regiao: {
      id: "1",
      uf: "N",
      nome: "Norte"
    }
  },
  {
    id: 32,
    uf: "ES",
    nome: "Espírito Santo",
    regiao: {
      id: 3,
      uf: "SE",
      nome: "Sudeste"
    }
  },
  {
    id: 50,
    uf: "MS",
    nome: "Mato Grosso do Sul",
    regiao: {
      id: 5,
      uf: "CO",
      nome: "Centro-Oeste"
    }
  },
  {
    id: 51,
    uf: "MT",
    nome: "Mato Grosso",
    regiao: {
      id: 5,
      uf: "CO",
      nome: "Centro-Oeste"
    }
  },
  {
    id: 52,
    uf: "GO",
    nome: "Goiás",
    regiao: {
      id: 5,
      uf: "CO",
      nome: "Centro-Oeste"
    }
  },
  {
    id: 28,
    uf: "TO",
    nome: "Tocantins",
    regiao: {
      id: 1,
      uf: "N",
      nome: "Norte"
    }
  },
  {
    id: 36,
    uf: "MT",
    nome: "Maranhão",
    regiao: {
      id: 5,
      uf: "CO",
      nome: "Centro-Oeste"
    }
  }
];
