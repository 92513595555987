import { ThemeProvider } from "@mui/material";
import Routers from "./routes/routes";
import { theme } from "./theme";
import { AuthProvider } from "./providers/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "moment/locale/pt-br";
import { ptBR } from "@mui/x-date-pickers/locales";
import { MobileModal } from "./components/MobileModal";
import { ParallaxProvider } from 'react-scroll-parallax';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./toast.css";

const brazilianLocale =
  ptBR.components.MuiLocalizationProvider.defaultProps.localeText;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale="pt-br"
        localeText={brazilianLocale}
      >
        <AuthProvider>
        <ParallaxProvider>
          <Routers />
          <ToastContainer />
          <MobileModal />
          </ParallaxProvider>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
