import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { GridMenuIcon } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import notificationIcon from "../../assets/notification.svg";
import logo from "../../assets/ecomlovers-logo-transparente.svg";
import logoPerfil from "../../assets/Artboard 3 copy 8.png";
import iconSino from "../../assets/Artboard 3 copy 21.png";
import iconBar from "../../assets/Group 1432.png";
import { Notifications } from "@mui/icons-material";
import useIsMobileDevice from "../../utils/mobileCheck";
import api from "../../utils/api";

export const MainTopbar = ({
  mainSidebarVisible,
  setMainSidebarVisible,
  notificationsVisible,
  setNotificationsVisible,
  loading,
  notifications,
}) => {
  const { signOut, setIsLoading, updateUser } = useContext(AuthContext);
  const { id, hash } = useParams();
  const { user } = useContext(AuthContext);
  const isMobile = useIsMobileDevice();
  const [avatarSrc, setAvatarSrc] = useState(user?.avatar || logoPerfil);

  const handleImageError = async () => {
    try {
      // const { data } = await api.get(`/talents/talents/${user?.id}`);
      // if (data?.data?.attributes?.avatar) {
      //   setAvatarSrc(data?.data?.attributes?.avatar);
      //   updateUser(data);
      // }
    } catch (error) {}
  };

  useEffect(() => {
    if (user.avatar) {
      // setAvatarSrc(user.avatar);
    }
  }, [user]);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const notificationsRead =
    notifications?.length > 0 &&
    notifications?.reduce(
      (acc, item) => (item.readed === false ? acc + 1 : acc),
      0
    );

  //   const title = () => {
  //     switch (pathname) {
  //       case "/clientes":
  //         return "Clientes";

  //       case "/dashboard":
  //         return "Dashboard";

  //       case "/atendente":
  //         return "Configurações do Atendente";

  //       case `/atendente/passoapasso`:
  //         return "Configurações do Atendente";

  //       case `/atendente/${hash}`:
  //         return "Configurações do Atendente";

  //       case `/conta`:
  //         return "Configurações de Conta";

  //       case `/agendamento`:
  //         return "Configurações de Agendamento";

  //       case `/chat/${id}`:
  //         return "Histórico de Conversa";

  //       case "/financeiro":
  //         return "Financeiro";

  //       case "/planos":
  //         return "Planos de Assinatura";

  //       case `/planos/${hash}`:
  //         return "Planos de Assinatura";

  //       case "/tokens":
  //         return "Recarga de Tokens";

  //       case "/carrinho":
  //         return "Resumo da Compra";

  //       case "/admin/parceiros":
  //         return "Parceiros";

  //       case "/admin/financeiro":
  //         return "Financeiro";

  //       case `/admin/parceiros/${id}`:
  //         return "Clientes do Parceiro";

  //       case "/vendas/clientes":
  //         return "Clientes";

  //       case "/vendas/crm":
  //         return "CRM";

  //       case "/vendas/dashboard":
  //         return "Dashboard";

  //       case "/vendas/demo":
  //         return "Bot de Demonstração";

  //       case `/vendas/conta`:
  //         return "Configurações de Conta";

  //       case "/vendas/financeiro":
  //         return "Financeiro";

  //       default:
  //         return "Não encontrado";
  //     }
  //   };

  // const handleAvatar = () => {
  //   if (user?.avatar) {
  //     return <Avatar src={user?.avatar} />;
  //   } else if (user?.name) {
  //     return <Avatar>{String(user?.name[0]).toUpperCase()}</Avatar>;
  //   } else if (user?.email) {
  //     return (
  //       <Avatar style={{ backgroundColor: "#EC5569" }}>
  //         {String(user?.email[0]).toUpperCase()}
  //       </Avatar>
  //     );
  //   } else {
  //     return <Avatar style={{ backgroundColor: "#EC5569" }} />;
  //   }
  // };

  function HideOnScroll(props) {
    const [showAppBar, setShowAppBar] = useState(true);
    const { children } = props;

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;

      // Se o scroll estiver no topo, manter o AppBar visível
      if (currentScroll < 50) {
        setShowAppBar(true);
      } else if (window.lastScroll > currentScroll) {
        // Se o usuário deslizar de cima para baixo
        setShowAppBar(true);
      } else {
        // Se o usuário deslizar de baixo para cima
        setShowAppBar(false);
      }

      window.lastScroll = currentScroll;
    };

    useEffect(() => {
      window.lastScroll = 0; // Inicializa a última posição de scroll
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
      <Slide appear={false} direction="down" in={showAppBar}>
        {children}
      </Slide>
    );
  }
  function isMobileDevice() {
    return window.innerWidth <= 800;
  }

  let width = isMobileDevice() ? "100vw" : "50vw";
  let left = isMobileDevice() ? "0px" : "50%";
  let transform = isMobileDevice() ? "0px" : "translateX(-50%";

  return (
    <HideOnScroll>
      <AppBar
        style={{
          backgroundColor: "#ffff",
          boxShadow: "0px 0px 0px 0px",
          borderBottom: "1px solid #F6F2F3",
          position: "fixed",
          width: width,
          left: left,
          transform: transform,
          top: '-2px'
        }}
      >
        <Toolbar
          sx={{
            minHeight: "75px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: 1 }}>
            <IconButton
              sx={{
                opacity: 1,
                "&:hover": {
                  opacity: 0.8,
                },
              }}
              style={{ padding: 0 }}
              onClick={() => {
                navigate("/settings");
                setIsLoading(false);
              }}
              onTouchEnd={() => {
                navigate("/settings");
                setIsLoading(false);
              }}

              // onClick={() => console.log("click")}
            >
              {/* {handleAvatar()} */}
              <img
                src={avatarSrc}
                alt=""
                className="iconToBarPerfil"
                style={{
                  width: "41px",
                  height: "41px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  overflow: "hidden",
                  border: user.avatar && "1px solid #f7f7f7",
                }}
                onError={handleImageError}
              />
            </IconButton>
          </div>
          <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <Button
              sx={{
                opacity: 1,
                "&:hover": {
                  opacity: 0.8,
                },
              }}
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                navigate("/home");
                setIsLoading(false);
              }}
              onTouchEnd={() => {
                navigate("/home");
                setIsLoading(false);
              }}
            >
              <img
                alt="eComLovers logo"
                src={logo}
                style={{ width: "116px" }}
              />
            </Button>
          </div>

          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              color="inherit"
              onClick={() => setNotificationsVisible(!notificationsVisible)}
              edge="start"
              style={{ color: "#211E1F" }}
            >
              {notificationsRead > 0 ? (
                <Typography
                  color={"white"}
                  fontSize={12}
                  fontWeight={700}
                  textAlign={"center"}
                  style={{
                    display: "flex",
                    position: "absolute",
                    border: "1px solid #EC5569",
                    backgroundColor: "#EC5569",
                    width: "5px",
                    height: "5px",
                    marginRight: "-30px",
                    borderRadius: "50%",
                    marginTop: -25,

                    justifyContent: "center",
                    alignItems: "center",

                    padding: 2,
                  }}
                ></Typography>
              ) : null}

              <img
                src={iconSino}
                // style={{ height: "35px" }}
                alt="notifications bell"
              />
            </IconButton>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setMainSidebarVisible(!mainSidebarVisible)}
              edge="start"
              style={{ color: "#211E1F" }}
            >
              {/* <GridMenuIcon /> */}
              <img
                src={iconBar}
                // style={{ height: "35px" }}
                alt="notifications bell"
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};
