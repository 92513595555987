import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import { Login } from "../screens/Login";
import Login from "../screens/Login/Login";
// import { SignUp } from "../screens/SignUp";
import SignUp from "../screens/SignUp/SignUp";
import { AuthContext } from "../providers/AuthProvider";
// import { ClientProfile } from "../screens/ClientProfile";
import ClientProfile from "../screens/ClientProfile/ClientProfile";
import CheckProfile from "../screens/ClientProfile/CheckProfile";
import { AuthorizedLayout } from "../components/AuthorizedLayout";
import { Skills } from "../screens/Skills";
import { UpdateSkills } from "../screens/UpdateSkills";
import { Home } from "../screens/Home";
import { Knowledge } from "../screens/Knowledge";
import SelfieLetter from "../screens/SelfieLetter";
import CulturalFIt from "../screens/CulturalFIt";
import { FAQ } from "../screens/FAQ";

//routes companies
import LoginCompanies from "../ScreensCompanies/Login/Login";
import SignUpCompanies from "../ScreensCompanies/SignUp/Index";
import Painels from "../ScreensCompanies/Painels/Painels";
import Complete from "../ScreensCompanies/SignUp/components/Complete";

const Routers = () => {
  const { signOut } = useContext(AuthContext);

  // if (
  //   window.location.protocol === "http:" &&
  //   window.location.hostname !== "localhost"
  // ) {
  //   window.location.href = window.location.href.replace("http:", "https:");
  //   return null;
  // }

  return (
    <BrowserRouter>
      <Routes>
        {/* CLIENT ROUTES */}
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/skills"
          element={
            <PrivateRoute>
              <Skills />
            </PrivateRoute>
          }
        />

        <Route
          path="/skills/:id"
          element={
            <PrivateRoute>
              <UpdateSkills />
            </PrivateRoute>
          }
        />

        <Route
          path="/knowledge"
          element={
            <PrivateRoute>
              <Knowledge />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <ClientProfile />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/status"
          element={
            <PrivateRoute>
              <CheckProfile />
            </PrivateRoute>
          }
        />

        <Route
          path="/selfie-letter"
          element={
            <PrivateRoute>
              <SelfieLetter />
            </PrivateRoute>
          }
        />

        <Route
          path="/fit-cultural"
          element={
            <PrivateRoute>
              <CulturalFIt />
            </PrivateRoute>
          }
        />

        <Route
          path="/faq"
          element={
            <PrivateRoute>
              <FAQ />
            </PrivateRoute>
          }
        />

        {/* PUBLIC ROUTES */}

        <Route
          exact
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />

        <Route
          exact
          path="/cadastro"
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />

        <Route
          exact
          path="/companies"
          element={
            <PublicRoute>
              <LoginCompanies />
            </PublicRoute>
          }
        />

        <Route
          exact
          path="/companies/registro"
          element={
            <PublicRoute>
              <SignUpCompanies />
            </PublicRoute>
          }
        />

        <Route
          exact
          path="/companies/registro/completar"
          element={
            <PublicRoute>
              <Complete />
            </PublicRoute>
          }
        />

        <Route
          exact
          path="/companies/painel"
          element={
            <PrivateRoute>
              <Painels />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<h1>404 Not found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

function PublicRoute({ children }) {
  const { user } = useContext(AuthContext);
  if (user) {
    if (user?.type === "talent") return <Navigate to="/home" />;
    return <Navigate to="/companies/painel" />;
  } else {
    return children;
  }
}

function PrivateRoute({ children }) {
  const { user } = useContext(AuthContext);
  if (!user) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}

export default Routers;
