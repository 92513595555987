import React from "react";
import { SidebarLink } from "./styles";
import home from "../../assets/home.svg";
import iconUsers from "../../assets/Vector (15).png";
import iconFInance from "../../assets/Group (2).png";

import './ListItems.css';
import { useLocation } from "react-router-dom";

export const MainListItems = () => {

  const { pathname } = useLocation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: '10px',
        marginTop: '70px'
      }}
    >
      <SidebarLink to={"/companies/painel"}>
        <div className={pathname === '/companies/painel' ? "itemLinkSelected" : 'itemLink'}>
          <img src={home} alt="icon" />
        </div>
      </SidebarLink>

      <SidebarLink to={"/companies/users"}>
        <div className={pathname === '/companies/users' ? "itemLinkSelected" : 'itemLink'}>
          <img src={iconUsers} alt="icon" />
        </div>
      </SidebarLink>

      <SidebarLink to={"/companies/finance"}>
        <div className={pathname === '/companies/finance' ? "itemLinkSelected" : 'itemLink'}>
          <img src={iconFInance} alt="icon" />
        </div>
      </SidebarLink>
    </div>
  );
};
