import React, { useContext } from "react";
import {
  SidebarLink,
} from "./styles";
import home from "../../assets/Artboard 3 copy 31 (1).svg";
import settings from "../../assets/Artboard 3 copy 8 (1).svg";
import knowledge from "../../assets/Artboard 3 copy 20.svg";
import skills from "../../assets/Artboard 3 copy 15.svg";
import iconFaq from "../../assets/Artboard 3 copy 4.svg";
import iconAcademy from "../../assets/Artboard 3 copy 25.svg";
import { AuthContext } from "../../providers/AuthProvider";

import './listItems.css';

export const MainListItems = () => {
const { setIsLoading } = useContext(AuthContext);

  return (
    <div
      className="containerListItens"
    >
      <SidebarLink to={"/home"}>
        <div className="itemList" onClick={() => setIsLoading(false)}>
          <img src={home} alt="house icon" />

          <div>Início</div>
        </div>
      </SidebarLink>

      <SidebarLink to={"/knowledge"}>
        <div  className="itemList" onClick={() => setIsLoading(false)}>
          <img src={knowledge} alt="medal icon" />
          <div>Conhecimentos</div>
        </div>
      </SidebarLink>

      <SidebarLink to={"/skills"} >
        <div className="itemList"onClick={() => setIsLoading(false)}>
          <img src={skills} alt="briefcase icon" />
          <div>Habilidades</div>
        </div>
      </SidebarLink>

      <SidebarLink to={"/settings"}>
        <div className="itemList" onClick={() => setIsLoading(false)}>
          <img src={settings} alt="gear icon" />
          <div>Perfil</div>
        </div>
      </SidebarLink>

      <SidebarLink to={"/faq"}>
        <div className="itemList" onClick={() => setIsLoading(false)}>
          <img src={iconFaq} alt="gear icon" />
          <div>Perguntas frequentes</div>
        </div>
      </SidebarLink>

      <SidebarLink to={"/"}>
        <div className="itemList" onClick={() => setIsLoading(false)}>
          <img src={iconAcademy} alt="gear icon" />
          <div>Academy</div>
        </div>
      </SidebarLink>

      {/* <SidebarItem
        button
        sx={{ position: "absolute", bottom: 0 }}
        onClick={() => signOut()}
      >
        <ListItemIcon sx={{ display: "flex", justifyContent: "center" }}>
          <Logout sx={{ color: "#211e1f" }} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ style: { fontSize: fontSize } }}
          primary="Sair"
        />
      </SidebarItem> */}
    </div>
  );
};
