import { useContext, useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Input,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import useIsMobileDevice from "../../utils/mobileCheck";
import { InputWithLabel } from "../../components/InputWithLabel";
import { useFormik } from "formik";
import profileCharacter from "../../assets/astronaut 3.svg";
import addPhoto from "../../assets/addPhoto.svg";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import unmask from "../../utils/unmask";
import { toast } from "react-toastify";
import moment from "moment";
import selectIcon from "../../assets/selectIcon.png";
import selectedIcon from "../../assets/selectedIcon.png";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import PropTypes from "prop-types";
// import { NewSlider } from "../SignUp/components/newSlider";
import NewSlider2 from "../SignUp/components/NewSlider2";
import { camelToSnake } from "../../utils/case";
import { Loader } from "../../components/Loader";
import { isNumber } from "@mui/x-data-grid/internals";
import iconHeart from "../../assets/Vector (23).png";

export const UpdateSkills = () => {
  const [selectedMenu, setSelectedMenu] = useState("basic");
  const isMobile = useIsMobileDevice();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectArea, setSelectedArea] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reqData, setReqData] = useState({
    questionId: null,
    optionId: null,
  });

  const [saveLoading, setSaveLoading] = useState(false);

  const { user, updateUser, handleAnswerAreas, handleAnswerKnowledge } =
    useContext(AuthContext);

  const options = [
    {
      id: 1,
      option_text: "Operação: Logística, SAC, RH, Administrativo",
    },
    {
      id: 2,
      option_text: "Marketing: SEO, Tráfego Pago, CRM, Copywriting",
    },
    {
      id: 3,
      option_text: "Marketplaces: MercadoLivre, Amazon, Magalu",
    },
    {
      id: 4,
      option_text: "Plataformas: VTEX, Nuvemshop, Shopify, Tray, WooCommerce",
    },
    {
      id: 5,
      option_text: "ERPs: Bling, Tiny, SAP, Linx, TOTVS, API Integration",
    },
    {
      id: 6,
      option_text:
        "Design \u0026 Media: Photoshop, Capcut, Premiere, Illustrator",
    },
    {
      id: 7,
      option_text: "Dados \u0026 BI: GA4, Data Studio, Hotjar, PowerBI",
    },
    {
      id: 8,
      option_text: "Social Media: Media buying, TikTok, YouTube, influencers",
    },
    {
      id: 9,
      option_text: "AI: ChatGPT, Midjourney, Adobe Sensei",
    },
    {
      id: 10,
      option_text: "Dia-a-dia",
    },
  ];

  const removerAposDoisPontos = (texto) => {
    // return texto;
    const indiceDoisPontos = texto.indexOf(":");
    if (indiceDoisPontos !== -1) {
      return texto.substring(0, indiceDoisPontos);
    }
    return texto;
  };

  const handleAreaLevel = (item, sliderValue) => {
    setSelectedArea((currentSelectedArea) => {
      const updatedArea = {
        questionIndex: currentSelectedArea.questionIndex,
        options: currentSelectedArea.options.reduce((acc, currentItem) => {
          if (currentItem.optionId === item.optionId) {
            acc.push({ ...currentItem, level: sliderValue });
          } else {
            acc.push(currentItem);
          }
          return acc;
        }, []),
      };
      // console.log("Updated Area:", updatedArea);
      return updatedArea;
    });
  };

  useEffect(() => {
    const areas = handleSkill(id);

    if (user[areas]) {
      getAreasQuestionOrder(areas);
    } else {
      getAreas(areas);
    }
  }, []);

  const getAreas = async (areas) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/questions/show_question/${camelToSnake(areas)}`
      );

      const { data: knowledge } = await api.get(
        `/questions/show_question/talent_knowledge_area`
      );

      console.log(data?.data);

      if (data?.data && knowledge?.data) {
        setReqData({
          questionId: knowledge.data[0].attributes.questionOrder,
          optionId: id,
        });

        setSelectedArea({
          questionIndex: data.data[0].attributes.questionOrder,
          options: data.data[0].attributes.options.map((item) => ({
            optionId: item.id,
            level: 0,
            optionText: item.optionText,
            logoUrl: item.logoUrl,
          })),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAreasQuestionOrder = async (areas) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/questions/show_question/${camelToSnake(areas)}`
      );

      // console.log(user[areas]);

      if (data?.data) {
        setSelectedArea({
          questionIndex: data.data[0].attributes.questionOrder,
          options: user[areas].map((item) => ({
            ...item,
            level: isNumber(item?.level)
              ? item?.level
              : handleValue(item?.level) || 0,
          })),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAreas = async () => {
    setSaveLoading(true);
    try {
      const updatedAreas = selectArea?.options?.map((item) => ({
        id: item.optionId,
        level: handleValue(item?.level) || item?.level,
      }));

      if (reqData?.optionId && reqData?.questionId) {
        await handleAnswerKnowledge(
          reqData?.questionId,
          [reqData?.optionId],
          user.authtoken
        );
      }

      const data = await handleAnswerAreas(
        selectArea.questionIndex,
        updatedAreas,
        user.authtoken
      );

      if (data.data) {
        updateUser(data);
        toast.success("Dados atualizados com sucesso!");
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const handleSkill = (value) => {
    const knowledgeAreas = {
      1: "operation",
      2: "marketing",
      3: "marketplace",
      4: "platform",
      5: "erp",
      6: "designAndMedia",
      7: "dataAndBi",
      8: "socialMedia",
      9: "ia",
      10: "dayToDay",
    };

    return knowledgeAreas[value] || false;
  };

  const handleValue = (value) => {
    const knowledgeAreas = {
      dont_know: 0,
      beginner: 1,
      can_manage: 2,
      can_teach: 3,
      expert: 4,
    };

    return knowledgeAreas[value] || false;
  };

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          justifySelf: 'center',
          padding: "10px 15px",
          marginTop: "20px",
          // position: "absolute",
          width: isMobile ? "90vw" : "800px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "100%" : "800px",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "15px",
              paddingBottom: "15px",
              width: "100%",
              borderBottom: "1px solid #F6F2F3",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Button
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
              color="secondary"
              style={{ textTransform: "none" }}
              onClick={() => navigate("/skills")}
            >
              <ArrowBackIos
                sx={{
                  width: "15px",
                  height: "15px",
                  color: "white",
                  padding: "5px",
                  backgroundColor: "#57BAB8",
                  borderRadius: "5px",
                  paddingLeft: "10px",
                }}
              />
              <div className="textSkil">{`Habilidades / `}</div>
              <div className="textSkil">
                {`
                ${removerAposDoisPontos(
                  options.find((item) => {
                    return Number(item?.id) === Number(id);
                  })?.option_text
                )}
                `}
              </div>
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              justifyContent: "space-around",
              marginTop: "25px",
              marginBottom: "25px",
              padding: "12px",
            }}
          >
            <div className="titlePageSKils">
              {`${removerAposDoisPontos(
                options.find((item) => {
                  return Number(item?.id) === Number(id);
                }).option_text
              )}`}
            </div>
            {loading ? (
              <Loader size={100} style={{ marginTop: "50px" }} />
            ) : (
              selectArea?.options?.length > 0 &&
              selectArea?.options?.map((item, index) => (
                <div
                  className="cardSlideSend"
                  key={index}
                  style={{ backgroundColor: "#F7F7F7" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: 'center',
                      width: '55px',
                      height: '45px',
                      borderRadius: '50%',
                      border: '1px solid black'
                    }}
                  >
                    {/* <Avatar /> */}
                    <img src={item?.logoUrl} alt="" className="iconTecnology" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div className="textCardSend">{item.optionText}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <NewSlider2
                        key={index}
                        item={{ ...item }}
                        handleAreaLevel={handleAreaLevel}
                      />
                    </div>
                  </div>
                </div>
              ))
            )}

            {!loading && (
              <button
                className="buttonSaveSKils"
                onClick={() => updateAreas()}
                disabled={saveLoading}
              >
                Salvar
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="footerMobile">
            Desenvolvido com
            <img src={iconHeart} alt="" />
            <strong>© eComLovers®</strong>
          </div>
    </AuthorizedLayout>
  );
};
