import { useContext, useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  Box,
  Button,
  Icon,
  IconButton,
  Modal,
  Rating,
  Typography,
} from "@mui/material";
import useIsMobileDevice from "../../utils/mobileCheck";
import { InputWithLabel } from "../../components/InputWithLabel";
import { useFormik } from "formik";
import profileCharacter from "../../assets/astronaut 3.svg";
import addPhoto from "../../assets/addPhoto.svg";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import unmask from "../../utils/unmask";
import { toast } from "react-toastify";
import moment from "moment";
import heart from "../../assets/heartIcon.svg";
import heartInactive from "../../assets/heartIconInactive.svg";
import info from "../../assets/info.svg";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import iconHeart from "../../assets/Vector (23).png";

import astrounautHoldingTablet from "../../assets/posesPNG/astronout-holding-tablet.png";

import "./Knowledge.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vW",
  bgcolor: "#2D2D2D",
  border: "1px solid #FFFFFF",
  p: "24px",
  borderRadius: "10px",
};

const KnowledgeExtraCard = ({ title, value, editable = false, item }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleKnowledge = (value) => {
    const knowledgeAreas = {
      0: "dont_know",
      1: "beginner",
      2: "can_manage",
      3: "can_teach",
      4: "expert",
    };

    return knowledgeAreas[value] || "dont_know";
  };


  const handleRatingChange = async (event, newValue) => {
    try {
      await api.put(`/talents/knowledge_skills/update_skill`, {
        data: {
          attributes: {
            id: Number(item.id),
            level: handleKnowledge(newValue),
          },
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <div className="KnowledgeCardKNow">
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div className="titleKnowCard">{title}</div>
        <IconButton sx={{ padding: 0 }} onClick={handleOpen}>
          <img src={info} alt="info" />
        </IconButton>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="titleModalKnow">{title}</div>
            <div className="subTitleModalKnow">{item?.description}</div>
          </Box>
        </Modal>
      </div>
      <Rating
        defaultValue={value}
        precision={1}
        max={3}
        icon={<img src={heart} alt="heart" />}
        emptyIcon={<img src={heartInactive} alt="heart" />}
        readOnly={!editable}
        onChange={handleRatingChange}
      />
    </div>
  );
};

const KnowledgeCard = ({ title, value, editable = false, item }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  return (
    <div className="KnowledgeCardKNow">
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div className="titleKnowCard">{title}</div>
        <IconButton sx={{ padding: 0 }} onClick={handleOpen}>
          <img src={info} alt="info" />
        </IconButton>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="titleModalKnow">{title}</div>
            <div className="subTitleModalKnow">{item?.description}</div>
          </Box>
        </Modal>
      </div>
      <Rating
        defaultValue={value}
        precision={1}
        max={3}
        icon={<img src={heart} alt="heart" />}
        emptyIcon={<img src={heartInactive} alt="heart" />}
        readOnly={!editable}
      />
    </div>
  );
};

export const Knowledge = () => {
  const [selectedMenu, setSelectedMenu] = useState("extraSkills");
  const [knowledge, setKnowledge] = useState([]);
  const [knowledgeExtra, setKnowledgeExtra] = useState([]);
  const isMobile = useIsMobileDevice();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const { user, updateUser, signOut } = useContext(AuthContext);
  const [selectKnowledge, setSelectedKnowledge] = useState([]);

  const removerAposDoisPontos = (texto) => {
    // return texto;
    const indiceDoisPontos = texto.indexOf(":");
    if (indiceDoisPontos !== -1) {
      return texto.substring(0, indiceDoisPontos);
    }
    return texto;
  };

  const handleKnowledge = (value) => {
    const knowledgeAreas = {
      dont_know: 0,
      beginner: 1,
      can_manage: 2,
      can_teach: 3,
      expert: 4,
    };

    return knowledgeAreas[value] || false;
  };

  const getKnowledge = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/knowledge_skills`);

      if (data.data) {
        setKnowledge(data.data.attributes.knowledgeAreaSkills);
        setKnowledgeExtra(data.data.attributes.otherKnowledgeSkills);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getKnowledge();
  }, [selectedMenu]);

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "100%" : "800px",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              width: "100%",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#BEFCF9",
                border: "1px solid #57BAB8",
                borderRadius: "10px",
                width: "100%",
                aspectRatio: 350 / 135,
                marginBottom: "28px",
                display: "flex",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "60%",
                  padding: "24px",
                }}
              >
                <div className="titleSKillsCard">
                  Atualização de conhecimentos
                </div>
                <div className="subTitleSKillsCard">
                  Aprimore suas especialidades para se destacar no mercado.
                </div>
              </Box>

              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "40%",
                }}
              >
                <img
                  src={astrounautHoldingTablet}
                  style={{
                    position: "absolute",
                    height: "115%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    top: "40%",
                    left: "40%",
                    transform: "translate(-50%, -50%)",
                  }}
                  alt=""
                />
              </div>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "6px",
              justifyContent: "space-around",
              paddingRight: "6px",
              paddingLeft: "6px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "#1E1E1E",
                borderRadius: "20px",
                marginBottom: "40px",
              }}
            >
              <div className="containerButonsKnow">
                <div className="containerBackgroundButons"></div>
                {/* <button
                  className="buttonSelectKnow"
                  style={{
                    backgroundColor: selectedMenu === "yourSkills" && "#F7F7F7",
                    color: selectedMenu === "yourSkills" && "#2D2D2D",
                  }}
                  onClick={() => {
                    setSelectedMenu("yourSkills");
                  }}
                >
                  Seus conhecimentos
                </button> */}
                <button
                  className="buttonSelectKnow"
                  style={{
                    backgroundColor:
                      selectedMenu !== "extraSkills" && "#F7F7F7",
                    color: selectedMenu !== "extraSkills" && "#2D2D2D",
                  }}
                  onClick={() => {
                    setSelectedMenu("extraSkills");
                  }}
                >
                  Conhecimentos Adicionais
                </button>
              </div>

              {selectedMenu === "yourSkills" ? (
                <Box
                  sx={{
                    padding: "25px",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "0px 0px 20px 20px",
                  }}
                >
                  <div className="textKnow">
                    Esses conhecimentos refletem as suas principais habilidades
                    e são automaticamente adicionados. Eles mostram o que você
                    já domina! Para explorar novas áreas, vá para{" "}
                    <strong>Conhecimentos Adicionais.</strong>
                  </div>

                  <div
                    style={{
                      gap: "12px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <KnowledgeCard title={"Gestão de Pessoas"} value={3} />
                    <KnowledgeCard title={"Tráfego"} value={1} />
                    <KnowledgeCard title={"SEO"} value={2} />
                    <KnowledgeCard title={"Análise de Dados"} value={2} />
                    <KnowledgeCard title={"Gestão de Produtos"} value={2} />
                    <KnowledgeCard title={"Atendimento ao Cliente"} value={3} /> */}

                    {loading ? (
                      <Loader size={100} style={{ marginTop: "20px" }} />
                    ) : (
                      knowledge?.length > 0 &&
                      knowledge.map((item, index) => (
                        <KnowledgeCard
                          key={index}
                          title={item.optionText}
                          value={handleKnowledge(item.level)}
                          item={item}
                        />
                      ))
                    )}
                  </div>
                </Box>
              ) : (
                <Box
                  sx={{
                    padding: "25px",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "0px 0px 20px 20px",
                  }}
                >
                  <div className="textKnow">
                    Quer expandir ainda mais suas competências? Aqui você pode
                    adicionar habilidades extras e destacar tudo o que mais te
                    interessa.
                  </div>

                  <div
                    style={{
                      gap: "12px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {loading ? (
                      <Loader size={100} style={{ marginTop: "20px" }} />
                    ) : (
                      knowledgeExtra?.length > 0 &&
                      knowledgeExtra.map((item, index) => (
                        <KnowledgeExtraCard
                          key={index}
                          title={item.optionText}
                          value={handleKnowledge(item.level)}
                          editable
                          item={item}
                        />
                      ))
                    )}

                    {/* <KnowledgeCard
                      title={"Gestão de Pessoas"}
                      value={0}
                      editable
                    />
                    <KnowledgeCard title={"Tráfego"} value={0} editable />
                    <KnowledgeCard title={"SEO"} value={0} editable />
                    <KnowledgeCard
                      title={"Análise de Dados"}
                      value={0}
                      editable
                    />
                    <KnowledgeCard
                      title={"Gestão de Produtos"}
                      value={0}
                      editable
                    />
                    <KnowledgeCard
                      title={"Atendimento ao Cliente"}
                      value={0}
                      editable
                    /> */}
                  </div>
                </Box>
              )}
            </Box>
          </div>
        </div>
      </div>
      <div className="footerMobile">
            Desenvolvido com
            <img src={iconHeart} alt="" />
            <strong>© eComLovers®</strong>
          </div>
    </AuthorizedLayout>
  );
};
