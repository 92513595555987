import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import { Loader } from "../../../components/Loader";
import { AuthContext } from "../../../providers/AuthProvider";
import astronauta from "../../../assets/image 14.png";
import astrounautPointingBoard from "../../../assets/posesPNG/astronout-pointing-board.png";
import astro1 from "../../../assets/Frame 35.png";
import astro2 from "../../../assets/image 15 (1).png";
import api from "../../../utils/api";

import "./Screen1.css";
import CulturalChart from "../../Home/graph";

const Screen1 = ({ setCurrentScreen }) => {
  // const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [videos, setVideos] = useState("");
  const [loading, setLoading] = useState(false);

  // console.log(user);

  const getVideos = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/selfie_letter`);
      setVideos(data?.data?.attributes?.Screen1Url || "");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="containerScreen1">
      <div className="cardSendVideo">
        <div className="containerText">
          <div className="title">Fit Cultural</div>
          <div className="subtitle">
            O questionário ajudará a determinar sua compatibilidade
          </div>
        </div>

        <div
          style={{
            position: "relative",
            height: "100%",
            width: "50%",
          }}
        >
          <img
            src={astrounautPointingBoard}
            style={{
              position: "absolute",
              height: "130%",
              maxWidth: "100%",
              objectFit: "contain",
              top: "30%",
              left: "50%",
              transform:
                "translate(-50%, -50%) scaleX(-1)" /* Gira a imagem 180 graus */,
              transformOrigin: "center" /* Rotaciona a partir do centro */,
            }}
            alt=""
          />
        </div>
      </div>

      {user?.isCulturalFitFilled ? (
        <>
          <div className="cardCenter">
          <CulturalChart />
          </div>
          <div
            className="buttonSelfie"
            onClick={() => setCurrentScreen("screen2")}
            style={{ marginBottom: '30px'}}
          >
            Responder novamente
          </div>
        </>
      ) : (
        <>
          <div className="cardCenter">
            <div className="text">
              Responda honestamente às próximas perguntas para avaliarmos a sua
              compatibilidade com nossas oportunidades!
            </div>

            <img src={astro2} alt="Screen1 icon" style={{ width: '176px', height: '184px'}} />
          </div>

          <div
            className="buttonSelfie"
            onClick={() => setCurrentScreen("screen2")}
          >
            Responder o Fit Cultural
          </div>
        </>
      )}
    </div>
  );
};

export default Screen1;
