import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#EC5569",
      light: "#FFE4E5",
    },
    secondary: {
      main: "#57BAB8",
      light: "#BEFCF9",
    },
  },
});
