import React, { useContext, useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";

import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import VideoThumbnail from "./components/VideoThumbnail";
import { Loader } from "../../components/Loader";
import { AuthContext } from "../../providers/AuthProvider";
import astronauta from "../../assets/astronauta-perfil.png";
import iconHeart from "../../assets/Vector (23).png";
import { uploadFile } from "../../utils/aws";
import api from "../../utils/api";

import "./SelfieLetter.css";
import CenterMode from "./components/CenterMode";

const SelfieLetter = () => {
  // const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [videos, setVideos] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const getVideos = async () => {
    setLoadingVideos(true);
    try {
      const { data } = await api.get(`/talents/selfie_letter`);
      setVideos(data?.data?.attributes?.selfieLetterUrl || "");
      setLoadingVideos(false);
    } catch (error) {
      setLoadingVideos(false);
    }
  };

  const deleteVideos = async () => {
    setLoadingDelete(true);
    try {
      await api.delete(`/talents/selfie_letter/delete`);
      setLoadingDelete(false);
      window.location.reload();
    } catch (error) {
      setLoadingDelete(false);
    }
  };

  const [progress, setProgress] = useState(0);

  const handleVideo = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setLoading(true);
      try {
        const upload = await uploadFile(selectedFile, (percent) => {
          setProgress(Math.min(percent, 100)); // Garante que o progresso não passe de 100%
        });

        if (upload?.file) {
          try {
            await api.post(`/talents/selfie_letter`, {
              data: {
                attributes: {
                  filename: upload?.file.filename,
                  byte_size: upload?.file.byte_size,
                  checksum: upload?.file.checksum,
                  key: upload?.file.key,
                  content_type: upload?.file.content_type,
                  metadata: {
                    user_id: user.id,
                  },
                },
              },
            });
            setLoading(false);
            setProgress(0);
            window.location.reload();
            toast.success("Video enviado com sucesso");
          } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("Erro ao enviar o video");
          }
        }
      } catch (error) {
        console.error("Erro ao enviar o video:", error);
        toast.error("Erro ao enviar o video");
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps


  useEffect(() => {
    getVideos();
  }, []);


  return (
    <AuthorizedLayout>
      {loadingVideos ? (
        <div className="containerLoader">
          <Loader />
        </div>
      ) : (
        <div className="containerSelfieLetter">
          <div className="cardSendVideo">
            <div className="containerText">
              <div className="title">Selfie Letter</div>
              <div className="subtitle1">
                Estamos entusiasmados em conhecer você melhor!
              </div>
              <div className="subtitle2">
                O Selfie Letter é uma oportunidade para você se apresentar de
                maneira autêntica
              </div>

              <label
                className={`buttonSend ${loading && "loading"}`}
                htmlFor="fupload"
                style={{ "--progress": `${progress}%` }} // Define a variável CSS para o progresso
              >
                <div htmlFor="fupload">
                  {loading
                    ? `${progress}%`
                    : videos !== ""
                    ? "Enviar Outro Vídeo"
                    : "Enviar Vídeo"}
                </div>
                <input
                  type="file"
                  id="fupload"
                  name="fupload"
                  className="fupload form-control"
                  accept=".mp4, .avi, .mov, .mkv, .wmv"
                  onChange={handleVideo}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            <img src={astronauta} alt="SelfieLetter icon" />
          </div>



          {videos === "" ? (
            <div
              style={{
                // margin: "10px 15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="webSelfie"
            >
              <div className="titleContemt" style={{ width: "80vw" }}>
                Siga os passos abaixo para gravar e enviar seu vídeo. Estamos
                ansiosos para ver sua apresentação!
              </div>

              <CenterMode />

              {/* <label className={`buttonSelfie ${loading && "loading"}`}>
                {loading ? "Enviando" : " Enviar Vídeo"}
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleVideo}
                />
              </label> */}

              <label
                className={`buttonSelfie ${loading && "loading"}`}
                htmlFor="fupload"
                style={{ "--progress": `${progress}%` }} // Define a variável CSS para o progresso
              >
                <div htmlFor="fupload">
                  {loading ? `${progress}%` : "Enviar Vídeo"}
                </div>
                <input
                  type="file"
                  id="fupload"
                  name="fupload"
                  className="fupload form-control"
                  accept=".mp4, .avi, .mov, .mkv, .wmv"
                  onChange={handleVideo}
                  style={{ display: "none" }}
                />
              </label>
            </div>
          ) : (
            <div style={{ padding: "10px 15px" }}>
              <div>
                <VideoThumbnail videoUrl={videos} />
              </div>

              <div
                className={`buttonRemove ${loadingDelete && "loading"}`}
                onClick={() => deleteVideos()}
              >
                Remover Vídeo
              </div>
            </div>
          )}
         <div className={videos === "" ? "footerMobile" : "footerMobileFixed"}>
            Desenvolvido com
            <img src={iconHeart} alt="" />
            <strong>© eComLovers®</strong>
          </div>
        </div>
      )}
    </AuthorizedLayout>
  );
};

export default SelfieLetter;
